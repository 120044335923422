import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import Alert from '@mui/material/Alert/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Spin from 'components/Spin';
import { Link } from 'react-router-dom';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { useStyles } from 'modules/dashboards/styles';

const DashboardCard = ({
  loading,
  title,
  rows,
  count,
  error,
  Icon,
  iconColor,
  getPrimaryText,
  getSecondaryText,
  renderActions,
  getLink,
  keyField,
  isEditable,
}) => {
  const classes = useStyles();
  return (
    <Paper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Spin spinning={loading}>
        {title && (
          <Box
            display="flex"
            py={1}
            px={2}
            mb={1}
            alignItems="center"
            className={isEditable && classes.gridItemTitle}
          >
            {Icon && (
              <SvgIcon sx={{ color: iconColor }} color={iconColor}>
                <Icon width={20} height={20} />
              </SvgIcon>
            )}
            <Typography variant="subtitle1" sx={{ flex: 1, ml: 1 }}>
              {title}
            </Typography>
            {count && <Typography variant="subtitle1">{count}</Typography>}
          </Box>
        )}
        <Box py={1}>
          {!rows?.length && !loading && (
            <Typography color="text.secondary" textAlign="center">
              No data found.
            </Typography>
          )}
          {error && <Alert severity="error">{error}</Alert>}
        </Box>
        {rows?.length ? (
          <List disablePadding sx={{ overflow: 'auto' }}>
            {rows.map((row) => {
              const primaryText = getPrimaryText(row);
              const secondaryText = getSecondaryText(row);
              const link = getLink && getLink(row);
              return (
                <ListItem
                  dense
                  sx={{ py: 0.25 }}
                  button={!!link}
                  disableRipple
                  component={!!link ? Link : undefined}
                  to={link}
                  key={row[keyField]}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      variant: 'body2',
                      color: 'text.secondary',
                    }}
                    secondaryTypographyProps={{
                      variant: 'body1',
                      color: 'text.primary',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                    sx={{ pr: 5 }}
                    title={secondaryText}
                    primary={primaryText}
                    secondary={secondaryText}
                  />
                  <ListItemSecondaryAction>
                    {renderActions && renderActions(row)}
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        ) : null}
      </Spin>
    </Paper>
  );
};

export default DashboardCard;
