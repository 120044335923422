import { useState } from 'react';
import { useFormikContext } from 'formik';
import Select from 'components/Form/Select';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from 'components/Form/TextInput';
import ColorPicker from 'components/Form/ColorPicker';
import Button from 'components/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DataTable from 'components/DataTable';
import IconButton from 'components/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { shortId } from 'utils';
import isNil from 'lodash/isNil';

const MAPPING_TYPES = {
  special: { value: 'special', label: 'Special' },
  range: { value: 'range', label: 'Range' },
  value: { value: 'value', label: 'Value' },
};

const MAPPING_SPECIALS = {
  is_nil: { value: 'is_nil', label: 'No Data' },
  is_nan: { value: 'is_nan', label: 'Is NaN' },
};

const ID = 'value_mappings';

export const evaluateCondition = (mapping, value) => {
  const { type, condition } = mapping;
  if (type === MAPPING_TYPES.value.value) {
    return value == condition;
  }
  if (type === MAPPING_TYPES.range.value) {
    const { from, to } = condition;
    const lb = isNil(from) ? true : from <= value;
    const ub = isNil(to) ? true : value <= to;

    return lb && ub;
  }
  if (type === MAPPING_TYPES.special.value) {
    if (condition === MAPPING_SPECIALS.is_nil.value) {
      return isNil(value);
    }
    if (condition === MAPPING_SPECIALS.is_nan.value) {
      return isNaN(value);
    }
  }
  return false;
};

const ValueMappings = ({ name }) => {
  const [open, setOpen] = useState(false);
  const { values, setFieldValue } = useFormikContext();

  const onUpdate = (values) => setFieldValue(`${name}.${ID}`, values);

  const columns = [
    {
      dataField: 'condition',
      text: 'condition',
      formatter: (value, row) => {
        let text = value;
        if (row.type === MAPPING_TYPES.range.value) {
          if (isNil(value.from)) {
            text = `${value.to} and lower`;
          } else if (isNil(value.to)) {
            text = `${value.from} and above`;
          } else {
            text = `[${value.from} - ${value.to}]`;
          }
        }
        if (row.type === MAPPING_TYPES.special.value) {
          text = MAPPING_SPECIALS[value].label;
        }
        return <Typography>{text}</Typography>;
      },
    },
    { dataField: 'replace_with', text: 'Replace with' },
    {
      dataField: 'color',
      formatter: (value, row) => (
        <ColorPicker key={row.id} value={value} disabled />
      ),
      sx: {
        width: '5%',
      },
    },
  ];

  const valueMappings = values[name][ID] || [];

  return (
    <>
      <Stack spacing={1}>
        <DataTable
          size="small"
          columns={columns}
          data={valueMappings}
          emptyMessage={'No value mappings'}
        />
        <Stack direction="row" justifyContent={'end'}>
          <Button
            size="small"
            variant="contained"
            color="neutral"
            justifyContent={'end'}
            onClick={() => setOpen(true)}
          >
            Edit
          </Button>
        </Stack>
      </Stack>

      <Dialog
        open={open}
        fullWidth
        maxWidth={'md'}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Edit Value Mappings</DialogTitle>
        <DialogContent>
          <EditValueMappings
            data={valueMappings}
            onClose={() => setOpen(false)}
            onUpdate={onUpdate}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

const EditValueMappings = ({ data, onClose, onUpdate }) => {
  const [valueMappings, setValueMappings] = useState(data);

  const onChange = (idx, value) =>
    setValueMappings(
      valueMappings.map((mapping, index) => (index === idx ? value : mapping)),
    );

  const onAdd = () =>
    setValueMappings([
      ...valueMappings,
      {
        id: shortId(),
        type: MAPPING_TYPES.value.value,
        color: '#4CBB17',
      },
    ]);

  const onRemove = (idx) =>
    setValueMappings([
      ...valueMappings.slice(0, idx),
      ...valueMappings.slice(idx + 1),
    ]);

  const onSave = () => {
    onUpdate(
      valueMappings.filter(({ type, condition }) => {
        if (type === MAPPING_TYPES.range.value) {
          return !isNil(condition.from) || !isNil(condition.to);
        }
        return !isNil(condition);
      }),
    );
    onClose();
  };

  const columns = [
    {
      dataField: 'type',
      text: 'Type',
      formatter: (value, row, idx) => (
        <Select
          value={value}
          onChange={(type) => onChange(idx, { ...row, type, condition: '' })}
          options={Object.values(MAPPING_TYPES)}
          transformValue
        />
      ),
      sx: {
        width: '15%',
      },
    },
    {
      dataField: 'condition',
      text: 'Condition',
      formatter: (value, row, idx) => {
        if (row.type === MAPPING_TYPES.range.value) {
          return (
            <Stack direction="row" spacing={1}>
              <TextField
                fullWidth
                size="small"
                margin="none"
                type="number"
                placeholder="From"
                value={value?.from}
                onChange={(e) => {
                  const from = e.target.value === '' ? null : +e.target.value;
                  onChange(idx, {
                    ...row,
                    condition: {
                      ...row.condition,
                      from,
                    },
                  });
                }}
              />
              <TextField
                fullWidth
                size="small"
                margin="none"
                type="number"
                placeholder="To"
                value={value?.to}
                onChange={(e) => {
                  const to = e.target.value === '' ? null : +e.target.value;
                  onChange(idx, {
                    ...row,
                    condition: {
                      ...row.condition,
                      to,
                    },
                  });
                }}
              />
            </Stack>
          );
        }
        if (row.type === MAPPING_TYPES.special.value) {
          return (
            <Select
              value={value}
              onChange={(condition) => onChange(idx, { ...row, condition })}
              options={Object.values(MAPPING_SPECIALS)}
              transformValue
            />
          );
        }
        return (
          <TextField
            fullWidth
            size="small"
            margin="none"
            placeholder="Value to match"
            value={value}
            onChange={(e) =>
              onChange(idx, { ...row, condition: e.target.value })
            }
          />
        );
      },
      sx: {
        width: '35%',
      },
    },
    {
      dataField: 'replace_with',
      text: 'Replace with (optional)',
      formatter: (value, row, idx) => (
        <TextField
          fullWidth
          size="small"
          margin="none"
          placeholder="Text to display"
          value={value}
          onChange={(e) =>
            onChange(idx, { ...row, replace_with: e.target.value })
          }
        />
      ),
      sx: {
        width: '35%',
      },
    },
    {
      dataField: 'color',
      formatter: (value, row, idx) => (
        <ColorPicker
          value={value}
          onChange={(color) => onChange(idx, { ...row, color })}
        />
      ),
      sx: {
        width: '5%',
      },
    },
    {
      dataField: 'id',
      formatter: (_, __, idx) => (
        <IconButton
          icon={<DeleteIcon />}
          onClick={() => onRemove(idx)}
          title="Delete value mapping"
        />
      ),
      sx: {
        width: '10%',
      },
    },
  ];

  return (
    <Stack spacing={3}>
      <Stack>
        <DataTable
          size="small"
          columns={columns}
          data={valueMappings}
          emptyMessage="No Value Mappings"
        />
        <Typography variant="body" color="text.secondary">
          Note: The first matched condition is applied
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" px={2}>
        <Button size="small" onClick={onAdd}>
          Add
        </Button>
        <Stack direction="row" spacing={2}>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onSave}>Update</Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export const CONFIG = {
  id: ID,
  name: 'Value Mappings',
  description: 'Customize Values',
  Component: ValueMappings,
  default: {
    [ID]: [],
  },
};

export default ValueMappings;
