import { DATA_SET } from 'constants/data-sources';

export const getColumns = (nodeConfigs) => {
  const columns = {};

  nodeConfigs.forEach((config, idx) => {
    const isMetricDS = config.dataset === DATA_SET.METRICS;
    const { nodeId } = config;

    if (config.type === 'query') {
      if (config.query_type === 'raw') {
        (config.columns || []).forEach((col) => {
          columns[col.field] = {
            label: col.field,
            value: col.field,
            type: col.field === 'duration' ? 'number' : col.type,
            nodeIdx: [idx],
            isRemoteSortable: false,
          };
        });
        return;
      }

      const { field, stat } = config.aggregation;

      if (isMetricDS) {
        const isPromQL = config.query_type === 'promql';
        const label = isPromQL ? nodeId : `${nodeId}:${stat}()`;

        columns[label] = {
          label: label,
          value: stat,
          type: 'number',
          nodeIdx: [idx],
          isRemoteSortable: true,
        };
      } else if (stat === 'COUNT') {
        const label = `${nodeId}:${stat}(${field})`;
        columns[label] = {
          label,
          value: 'count(*)',
          type: 'number',
          nodeIdx: [idx],
          isRemoteSortable: true,
        };
      } else if (stat === 'COUNT_DISTINCT') {
        const label = `${nodeId}:${stat}(${field})`;
        columns[label] = {
          label,
          value: 'COUNT_DISTINCT',
          type: 'number',
          nodeIdx: [idx],
          isRemoteSortable: true,
        };
      } else if (stat.startsWith('P')) {
        const label = `${nodeId}:${stat}(${field})`;
        columns[label] = {
          label,
          value: stat,
          type: 'number',
          nodeIdx: [idx],
          isRemoteSortable: true,
        };
      } else {
        const value = `${stat}(${field})`;
        const label = `${nodeId}:${value}`;
        columns[label] = {
          label,
          value,
          type: 'number',
          nodeIdx: [idx],
          isRemoteSortable: true,
        };
      }

      config.groupBy.forEach((gb) => {
        const key = `${gb.field}${gb.type}`;
        if (columns[key]) {
          columns[key] = {
            ...columns[key],
            nodeIdx: columns[key].nodeIdx.concat(idx),
          };
        } else {
          columns[key] = {
            label: gb.field,
            value: gb.field,
            type: gb.type,
            nodeIdx: [idx],
            isRemoteSortable: true,
          };
        }
      });
    } else {
      columns[nodeId] = {
        label: nodeId,
        value: nodeId,
        type: 'number',
        nodeIdx: [idx],
        isRemoteSortable: false,
      };
    }
  });

  return Object.values(columns);
};
