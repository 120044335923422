import React, { useState, useMemo, useEffect, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import capitalize from 'lodash/capitalize';
import { Field, FieldArray, useFormikContext } from 'formik';
import Box from '@mui/material/Box';
import Select from 'components/Form/Formik/FormikReactSelect';
import REGIONS, { DEFAULT_REGION } from 'constants/aws-regions';
import CustomSelect from 'components/Form/Select';
import Button from 'components/Button';
import { useLazyQuery } from '@apollo/client';
import {
  GET_METRIC_DIMENSION_KEYS,
  GET_DIMENSION_VALUES,
  GET_NAMESPACE_METRICS,
  GET_NAMESPACES,
} from 'graphql/metric/queries';
import { GET_ACCOUNTS_BY_WORKSPACE_ID } from 'graphql/accounts/queries';
import Typography from '@mui/material/Typography';
import { parseType, resolveTemplateVariables } from 'modules/dashboards/utils';
import { DATA_SET } from 'constants/data-sources';
import { fetchKeys, fetchValues } from 'api/otel';
import Filters from 'modules/alarms/components/kloudmate/Filters';
import { parseValue } from 'components/DateRangePickerNew';
import get from 'lodash/get';
import { Dimensions } from 'modules/alarms/components/cloudwatch/CloudwatchQueryForm';

export const OTEL_DATASET = 'otel.dataset';
export const OTEL_KEY = 'otel.key';
export const OTEL_VALUES = 'otel.values';

const getQueryTypes = (source) => {
  if (!source) {
    return [];
  }
  return [
    {
      type: 'datasource',
      source,
      label: 'Data Source',
      value: `datasource`,
    },
    {
      type: 'regions',
      source,
      label: 'AWS Region',
      value: `regions`,
    },
    {
      type: 'namespaces',
      source,
      label: 'AWS Namespace',
      value: `namespaces`,
    },
    {
      type: 'metrics',
      source,
      label: 'AWS Metric',
      value: `metrics`,
    },
    {
      type: 'dimension_keys',
      source,
      label: 'AWS Dimension Key',
      value: `dimension_keys`,
    },
    {
      type: 'dimension_values',
      source,
      label: 'AWS Dimension Values',
      value: `dimension_values`,
    },
    {
      type: OTEL_DATASET,
      label: 'OTel Dataset',
      value: OTEL_DATASET,
    },
    {
      type: OTEL_KEY,
      label: 'OTel Attribute Key',
      value: OTEL_KEY,
    },
    {
      type: OTEL_VALUES,
      label: 'OTel Attribute Values',
      value: OTEL_VALUES,
    },
  ];
};

const QueryVariable = ({
  workspaceId,
  variable,
  setPreview,
  allVariables,
  isViewMode,
  viewModeOnChange,
  resolvedVariablesValues,
  defaultRegion,
  timeRange,
}) => {
  const [namespaceQuery] = useLazyQuery(GET_NAMESPACES);
  const [metricQuery] = useLazyQuery(GET_NAMESPACE_METRICS, {
    fetchPolicy: 'cache-first',
  });
  const [keysQuery] = useLazyQuery(GET_METRIC_DIMENSION_KEYS, {
    fetchPolicy: 'cache-first',
  });
  const [valuesQuery] = useLazyQuery(GET_DIMENSION_VALUES, {
    fetchPolicy: 'cache-first',
  });
  const [accountQuery] = useLazyQuery(GET_ACCOUNTS_BY_WORKSPACE_ID, {
    fetchPolicy: 'cache-first',
  });

  const formik = useFormikContext();
  const parsedTime = useMemo(() => {
    return parseValue(timeRange || '1h');
  }, [timeRange]);

  const [dependenciesList, setDependenciesList] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(async () => {
    const { queryType } = variable.config;

    if (!queryType) {
      setDependenciesList([]);
    }
    const { dependsOn: dependencies } = getQueryConfig(queryType);

    const dependencyOptions = [];
    for (let depType of dependencies) {
      const { optionsResolver, dependsOn } = getQueryConfig(depType);
      const isDependencyPending = dependsOn.some(
        (dep) => !get(variable.config, dep),
      );
      if (isDependencyPending) {
        dependencyOptions.push({ options: [], type: depType });
      } else {
        const options = await optionsResolver(variable);

        dependencyOptions.push({
          options: (allVariables || []).length
            ? allVariables.concat(
                options.map((option) => ({
                  ...option,
                  groupBy: capitalize(depType),
                })),
              )
            : options,
          type: depType,
        });
      }
    }

    setDependenciesList(dependencyOptions);
  }, [variable.config]);

  useEffect(async () => {
    const { queryType } = variable.config;

    if (!queryType) {
      return;
    }

    const { optionsResolver, dependsOn } = getQueryConfig(queryType);
    const isDdependencyPending = dependsOn.some(
      (dep) => !get(variable.config, dep),
    );
    if (!isDdependencyPending) {
      const result = await optionsResolver();
      let options = [...result, { label: 'None', value: 'none' }];
      if (variable.show_all_option) {
        options = [{ label: 'All', value: '*' }, ...options];
      } else {
        options = options.filter((o) => o.value !== '*');
      }
      setOptions(options);
      setPreview && setPreview(options);
    }
  }, [variable.config, resolvedVariablesValues, variable.show_all_option]);

  const resolveInput = useCallback(
    (input) => {
      if (!input) {
        return;
      }
      const res = resolveTemplateVariables(
        { node_configs: [{ type: 'query', input }] },
        resolvedVariablesValues || [],
        defaultRegion,
      );

      return res.node_configs[0].input;
    },
    [resolvedVariablesValues],
  );

  const getQueryConfig = (queryType) => {
    const compare = queryType || variable.config.query_type;
    switch (compare) {
      case `datasource`: {
        return {
          optionsResolver: async () => {
            const result = await accountQuery({
              variables: {
                workspaceId,
              },
            });

            return (result?.data?.accounts || [])
              .map((acc) => ({
                label: `aws/${acc.name}`,
                value: acc.id,
              }))
              .concat([
                {
                  label: 'OpenTelemetry',
                  value: 'kloudmate',
                },
              ]);
          },
          dependsOn: [],
        };
      }
      case `regions`: {
        return {
          optionsResolver: () => {
            const regions = REGIONS.map((i) => ({
              label: i.region,
              value: i.region,
            }));
            return [{ label: 'default', value: '$default' }].concat(regions);
          },
          dependsOn: [],
        };
      }
      case `namespaces`: {
        return {
          optionsResolver: async () => {
            const { source } = variable.config;

            const result = await namespaceQuery({
              variables: {
                source: resolveInput(source),
              },
            });

            return result?.data?.rows || [];
          },
          dependsOn: [],
        };
      }
      case `metrics`: {
        return {
          optionsResolver: async () => {
            const { source, namespaces, regions, datasource } = variable.config;
            const result = await metricQuery({
              variables: {
                namespace: resolveInput(namespaces),
                accountId: resolveInput(datasource),
                region: resolveInput(regions),
                source: resolveInput(source),
              },
            });

            return result?.data?.rows || [];
          },
          dependsOn: ['datasource', 'regions', 'namespaces'],
        };
      }
      case `dimension_keys`: {
        return {
          optionsResolver: async () => {
            const {
              source,
              namespaces,
              metrics,
              regions,
              dimensions,
              datasource,
            } = variable.config;

            const resolvedDimenstionFilters = resolveInput(dimensions) || {};

            const result = await keysQuery({
              variables: {
                namespace: resolveInput(namespaces),
                accountId: resolveInput(datasource),
                region: resolveInput(regions),
                metricName: resolveInput(metrics),
                dimensionFilters: resolvedDimenstionFilters,
                source,
              },
            });

            return result?.data?.rows || [];
          },
          dependsOn: ['datasource', 'regions', 'namespaces', 'metrics'],
        };
      }
      case `dimension_values`: {
        return {
          optionsResolver: async () => {
            const {
              source,
              namespaces,
              metrics,
              regions,
              dimension_keys,
              dimensions,
              datasource,
            } = variable.config;

            const resolvedDimenstionFilters = resolveInput(dimensions) || {};
            const result = await valuesQuery({
              variables: {
                namespace: resolveInput(namespaces),
                accountId: resolveInput(datasource),
                region: resolveInput(regions),
                metricName: resolveInput(metrics),
                dimensionKey: resolveInput(dimension_keys),
                dimensionFilters: resolvedDimenstionFilters,
                source,
              },
            });

            if (result?.data?.rows.length) {
              if (Object.keys(resolvedDimenstionFilters)) {
                return result?.data?.rows;
              }
              return [{ label: 'All', value: '*' }, ...result?.data?.rows];
            }
            return [];
          },
          dependsOn: [
            'datasource',
            'regions',
            'namespaces',
            'metrics',
            'dimension_keys',
          ],
        };
      }
      case OTEL_DATASET: {
        return {
          optionsResolver: () => [
            { label: 'Metrics', value: DATA_SET.METRICS },
            { label: 'Traces', value: DATA_SET.TRACES },
            { label: 'Logs', value: DATA_SET.LOGS },
          ],
          dependsOn: [],
        };
      }
      case OTEL_KEY: {
        return {
          optionsResolver: async () => {
            const {
              otel: { dataset, filters, key },
            } = variable.config;

            let query = '';
            if (variable.is_multi) {
              query = (variable.value || [])[variable.value?.length];
            } else {
              query = variable.value;
            }

            try {
              const result = await fetchKeys({
                dataset: resolveInput(dataset),
                query: {
                  query: query || key || '',
                  workspaceId,
                  from: parsedTime?.from,
                  to: parsedTime?.to,
                  filters: resolveInput(filters),
                },
              });

              return result.map((row) => ({
                label: row.field,
                value: {
                  field: row.field,
                  type: row.type,
                },
              }));
            } catch (err) {
              return [];
            }
          },
          dependsOn: [OTEL_DATASET],
        };
      }
      case OTEL_VALUES: {
        return {
          optionsResolver: async () => {
            const {
              otel: { dataset, filters, key },
            } = variable.config;

            let field, type;
            if (typeof key === 'object') {
              field = key.field;
              type = key.type;
            } else {
              const resolved = resolveInput({
                field: key,
                type: parseType(key),
              });
              field = resolved.field;
              type = resolved.type;
            }

            let query = '';
            if (isViewMode) {
              if (variable.is_multi) {
                query = (variable.value || [])[variable.value?.length];
              } else {
                query = variable.value;
              }
            }

            try {
              const result = await fetchValues({
                dataset: resolveInput(dataset),
                query: {
                  query,
                  workspaceId,
                  key: field,
                  type,
                  from: parsedTime?.from,
                  to: parsedTime?.to,
                  filters: resolveInput(filters),
                },
              });

              return result.values.map((row) => ({
                label: row.value,
                value: row.value,
              }));
            } catch (err) {
              return [];
            }
          },
          dependsOn: [OTEL_DATASET, OTEL_KEY],
        };
      }
      default: {
        return { optionsResolver: () => {}, dependsOn: [] };
      }
    }
  };

  const dimensionFilters = useMemo(() => {
    return resolveInput(variable.config.dimensions) || [];
  }, [variable.config.dimensions]);

  useEffect(() => {
    if (variable.value || !viewModeOnChange) {
      return variable.value;
    }
    if (variable.config.queryType === 'regions') {
      viewModeOnChange(DEFAULT_REGION);
    }
    if (variable.config.queryType === 'datasource') {
      if (options.length) {
        viewModeOnChange(options[0].value);
      }
    }
  }, [variable.value, options?.length]);

  const freeSoloQueries = [
    'dimension_values',
    'namespaces',
    OTEL_KEY,
    OTEL_VALUES,
  ];
  const freeSolo = freeSoloQueries.includes(variable.config.queryType);

  const getLabel = (option) => {
    return option.field || option.label || option || '';
  };

  const isOptionEqualToValue = (option, value) => {
    const lhs =
      typeof option === 'string'
        ? option
        : option.field
        ? `${option.field}_${option.type}`
        : option.value;
    const rhs =
      typeof value === 'string'
        ? value
        : value.field
        ? `${value.field}_${value.type}`
        : value.value;

    return lhs === rhs;
  };

  return !isViewMode ? (
    <Box>
      <Grid container columnSpacing={2} sx={{ mb: 1 }}>
        <Grid item md={6}>
          <Field
            transformValue
            margin="none"
            name={`config.queryType`}
            label="Query type"
            component={Select}
            onChange={(value) => {
              if (formik) {
                formik.setFieldValue('config.queryType', value);
                formik.setFieldValue('show_all_option', false);
                formik.setFieldValue('is_multi', false);
              }
            }}
            options={getQueryTypes(variable.config.source)}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" columnSpacing={2}>
        {dependenciesList.map((dep, index) => {
          return (
            <Grid key={index} item md={6} sx={{ mb: 1 }}>
              <Field
                transformValue
                margin="none"
                name={`config.${dep.type}`}
                label={
                  getQueryTypes(variable.config.source).find(
                    ({ type }) => type === dep.type,
                  ).label
                }
                component={Select}
                options={dep.options}
                freeSolo={freeSoloQueries.includes(dep.type)}
                getOptionLabel={getLabel}
                isOptionEqualToValue={isOptionEqualToValue}
              />
            </Grid>
          );
        })}
        {variable.config.queryType === 'dimension_values' && (
          <Grid item sm={12}>
            <Typography gutterBottom variant="text" color="text.secondary">
              Dimensions
            </Typography>
            <Dimensions
              name={'config'}
              variableOptions={allVariables}
              accountId={resolveInput(variable.config.datasource)}
              namespace={resolveInput(variable.config.namespaces)}
              region={resolveInput(variable.config.regions)}
              metricName={resolveInput(variable.config.metrics)}
              source={resolveInput(variable.config.source)}
              dimensionFilters={dimensionFilters}
              resolvedVariablesValues={resolvedVariablesValues}
            />
          </Grid>
        )}
        {[OTEL_KEY, OTEL_VALUES].includes(variable.config.queryType) && (
          <Grid item sm={12}>
            <Typography gutterBottom variant="text" color="text.secondary">
              Filters
            </Typography>
            <Filters
              queryOptions={
                variable.config.otel?.filters
                  ? variable.config.otel
                  : { filters: [] }
              }
              onChange={(name, value) => {
                formik?.setFieldValue(name, value);
              }}
              variableOptions={allVariables}
              name={`config.otel`}
              from={parsedTime?.from}
              to={parsedTime?.to}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  ) : (
    options && (
      <CustomSelect
        transformValue
        margin="none"
        size="small"
        value={variable.value}
        options={options}
        onChange={viewModeOnChange}
        sx={{
          width: 190,
        }}
        freeSolo={freeSolo}
        getOptionLabel={getLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        isMulti={variable.is_multi}
      />
    )
  );
};

export default QueryVariable;
