import React from 'react';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useFormikContext, Field } from 'formik';
import Select from 'components/Form/Formik/FormikReactSelect';
import last from 'lodash/last';
import mean from 'lodash/mean';
import max from 'lodash/max';
import min from 'lodash/min';
import sum from 'lodash/sum';
import TextField from 'components/Form/Formik/FormikInput';

export const VALUES_OPTIONS = {
  reduce: { value: 'reduce', label: 'Reduce' },
  all_values: { value: 'all_values', label: 'All Values' },
};

export const PIE_REDUCERS = {
  max: { value: 'max', label: 'Max' },
  min: { value: 'min', label: 'Min' },
  last: { value: 'last', label: 'Last' },
  total: { value: 'total', label: 'Total' },
  mean: { value: 'mean', label: 'Mean' },
};

export const COL_OPTIONS = {
  all: { value: 'all', label: 'All Columns' },
  numeric: { value: 'numeric', label: 'Numeric Columns' },
  timestamp: { value: 'timestamp', label: 'timestamp' },
};

const ID = 'value_options';

export const pieValuesReducer = (values, reducer, numeric) => {
  switch (reducer) {
    case PIE_REDUCERS.last.value: {
      return last(values);
    }
    case PIE_REDUCERS.min.value: {
      return numeric ? min(values) : '-';
    }
    case PIE_REDUCERS.max.value: {
      return numeric ? max(values) : '-';
    }
    case PIE_REDUCERS.total.value: {
      return numeric ? sum(values) : '-';
    }
    case PIE_REDUCERS.mean.value: {
      return numeric ? mean(values) : '-';
    }
    default:
      return null;
  }
};

export const getColumnId = (frame, useNodeId) => {
  const labelValues = Object.values(frame.labels);
  let id;
  if (labelValues.length) {
    id = labelValues.join(' ');
  } else {
    id = frame.name;
  }
  return `${useNodeId ? `${frame.nodeId} ` : ''}${id}`;
};

const ValueOptions = ({ name, frames = [] }) => {
  const { values, setFieldValue } = useFormikContext();

  const hasMultipleNodes = values.node_configs.length > 1;
  const colOptions = Object.values(COL_OPTIONS).concat(
    frames.map((series) => {
      const colName = getColumnId(series, hasMultipleNodes);
      return {
        label: colName,
        value: colName,
      };
    }),
  );

  return (
    <Stack spacing={1}>
      <ToggleButtonGroup
        exclusive
        variant="outlined"
        size="small"
        value={values[name][ID].type}
        onChange={(e, v) => {
          setFieldValue(`${name}.${ID}.type`, v);
        }}
      >
        {Object.values(VALUES_OPTIONS).map(({ label, value }, index) => {
          return (
            <ToggleButton disableRipple key={index} value={value}>
              {label}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      {values[name][ID].type === VALUES_OPTIONS.reduce.value && (
        <Field
          fullwidth
          size="small"
          label={'Reduce Function'}
          name={`${name}.${ID}.reduce`}
          component={Select}
          transformValue
          options={Object.values(PIE_REDUCERS)}
        />
      )}
      {values[name][ID].type === VALUES_OPTIONS.all_values.value && (
        <Field
          fullwidth
          size="small"
          label={'Limit'}
          name={`${name}.${ID}.limit`}
          component={TextField}
        />
      )}
      <Field
        fullwidth
        isMulti
        size="small"
        label={'Columns'}
        name={`${name}.${ID}.columns`}
        component={Select}
        transformValue
        options={colOptions}
      />
    </Stack>
  );
};

export const CONFIG = {
  id: ID,
  name: 'Value Options',
  description: 'Customize Values',
  Component: ValueOptions,
  default: {
    value_options: {
      type: VALUES_OPTIONS.reduce.value,
      reduce: PIE_REDUCERS.last.value,
      limit: 20,
      columns: [COL_OPTIONS.numeric.value],
    },
  },
};

export default ValueOptions;
