import React, { useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import Map from './Map/Map';
import CostPerService from './CostPerService/CostPerService';
import MonthlyBarData from './MonthlyBarChart/MonthlyBarData';
import { useSelector } from 'store';
import TimeRangeSelect from './components/TimeRangeSelect';
import ActiveAlarms from 'modules/dashboard/components/ActiveAlarms';
import RecentIssues from 'modules/dashboard/components/RecentIssues';
import PageContainer from 'components/PageContainer';
import { parseValue } from 'components/DateRangePickerNew';
import usePageEvent from 'hooks/usePageEvent';
import TraceSummary from './components/TraceSummary';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ACCOUNTS_BY_WORKSPACE_ID } from 'graphql/accounts/queries';
import Stack from '@mui/material/Stack';
import AddWidgetModal from './components/AddWidgetModal';
import {
  GET_USER_DASHBOARD,
  SAVE_USER_DASHBOARD,
  UPDATE_USER_DASHBOARD,
} from 'graphql/dashboard/queries';
import { toast } from 'react-hot-toast';
import { DEFAULT_REGION } from 'constants/aws-regions';
import { getFirstName, shortId } from 'utils';
import usePrevious from 'hooks/usePrevious';
import max from 'lodash/max';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import GridLayoutChild from 'components/GridLayoutChild';
import IconButton from 'components/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import { analyticsPage, analyticsTrack } from 'utils/segment';
import EditPanelModal from 'modules/dashboards/components/EditPanelModal';
import Panel from 'modules/dashboards/components/Panel';
import NewPanelWizard from 'modules/dashboards/components/NewPanelWizard';
import { NEW_PANEL } from 'modules/dashboards/components/Dashboard';
import AddchartIcon from '@mui/icons-material/Addchart';
import WidthProvider from 'components/WidthProvider';
import { Responsive } from 'react-grid-layout';
import { useStyles } from 'modules/dashboards/styles';
import useAlert from 'hooks/useAlert';
import {
  DEFAULT_LAYOUT_CONFIG,
  getInterval,
  widgets_config,
  variables,
} from './utils';
import cloneDeep from 'lodash/cloneDeep';
import VariablesBar from 'modules/dashboards/components/VariablesBar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import { resolveTemplateVariables } from 'modules/dashboards/utils';

const LG = 1280;
const MD = 992;
const SM = 768;
const XS = 480;
const XXS = 0;

const Home = () => {
  const [clickedRegion, setClickedRegion] = React.useState();
  const [clickedCountry, setClickedCountry] = React.useState();
  const [timeRange, setTimeRange] = React.useState(6);
  const [selectedPanel, setSelectedPanel] = useState(null);
  const [isEditing, setEditing] = useState(false);
  const [showAddWidgetModal, setShowAddWidgetModal] = useState(false);
  const [autoSelectTemplate, setAutoSelectTemplate] = useState(null);
  const [showWizard, setShowWizard] = useState(false);
  const classes = useStyles();
  const alert = useAlert();

  const { workspace, user } = useSelector((s) => s);
  const defaultWorkspaceId =
    workspace &&
    user.acl.find((i) => i.workspace_id === workspace.workspace.id).organization
      .default_workspace_id;

  const [forecastAccountId, setForecastAccountId] = useState();
  const [mapAccountId, setMapAccountId] = useState();
  const [costAccountId, setCostAccountId] = useState();
  const [userDashboard, setUserDashboard] = useState(null);
  const [variableDialog, setVariableDialog] = useState(false);
  const [varaibleValue, setVariableValue] = useState(variables);
  const [lambdaWidget, setLambdaWidget] = useState(null);

  const { data, loading, error } = useQuery(GET_USER_DASHBOARD, {
    variables: {
      workspaceId: workspace.workspace.id,
      userId: user.id,
    },
    fetchPolicy: 'cache-first',
  });

  const [saveUserDashboard] = useMutation(SAVE_USER_DASHBOARD, {
    update: (cache, { data }) => {
      const userDashboard = data.row;

      cache.writeQuery({
        query: GET_USER_DASHBOARD,
        variables: {
          workspaceId: workspace.workspace.id,
          userId: user.id,
        },
        data: {
          rows: [userDashboard],
        },
      });
    },
  });

  const [updateUserDashboard] = useMutation(UPDATE_USER_DASHBOARD, {
    update: (cache, { data }) => {
      const userDashboard = data.row;

      cache.writeQuery({
        query: GET_USER_DASHBOARD,
        variables: {
          workspaceId: workspace.workspace.id,
          userId: user.id,
        },
        data: {
          rows: [userDashboard],
        },
      });
    },
  });

  const accountsResp = useQuery(GET_ACCOUNTS_BY_WORKSPACE_ID, {
    variables: {
      workspaceId: workspace.workspace.id,
    },
    onCompleted: (data) => {
      if (data.accounts.length > 0) {
        setForecastAccountId(data.accounts[0].id);
        setMapAccountId(data.accounts[0].id);
        setCostAccountId(data.accounts[0].id);
      } else {
        setForecastAccountId(null);
        setMapAccountId(null);
        setCostAccountId(null);
      }
    },
  });

  const { timestamp, interval } = useMemo(() => {
    return {
      timestamp: parseValue(timeRange + 'h'),
      interval: getInterval(timeRange),
    };
  }, [timeRange]);

  const startTime = useMemo(
    () => moment().subtract(timeRange, 'hours').toDate(),
    [timeRange],
  );
  const endTime = useMemo(() => moment().toDate(), []);

  useEffect(() => {
    isEditing &&
      analyticsPage('Edit Home', {
        section: 'Edit Home',
        flow: 'Home',
      });
  }, [isEditing]);

  usePageEvent('Home', {
    section: 'Home',
    flow: 'Home',
  });

  useEffect(async () => {
    if (!data) {
      setUserDashboard(null);
    } else if (!data?.rows?.length) {
      const default_widgets = [
        'span_count',
        'log_count',
        'metrics_points_count',
        'services_summary',
        'recent_issues',
        'active_alarms',
        'aws_cost_graph',
        'aws_resources_map',
        'aws_cost_breakdown',
      ];
      const widgets = default_widgets.map((widget) => {
        const widgetConfig = cloneDeep(widgets_config[widget]);
        if (widget === 'metrics_points_count') {
          widgetConfig.node_configs[0].filters = [
            {
              field: 'workspaceId',
              type: 'string',
              operator: '=',
              value: workspace.workspace.id,
            },
          ];
        }
        return {
          ...widgetConfig,
          id: shortId(),
          widget,
        };
      });

      const widget_layouts = widgets.map((widgetObj) => {
        const widget = widgetObj.widget;
        const layout = { ...DEFAULT_LAYOUT_CONFIG[widget] };
        layout.i = widgetObj.id;
        return layout;
      });

      const dashboard = {
        user_id: user.id,
        dashboard: {
          time_config: {
            type: 'relative',
            value: 6,
            unit: 'h',
            tz: 'local',
          },
          widgets,
          widget_layouts,
          default_region: DEFAULT_REGION,
        },
      };
      setUserDashboard(dashboard);
      await onBackgroundSave(dashboard);
    } else {
      setUserDashboard(data?.rows[0]);
    }
  }, [data]);

  const lastRow = useMemo(
    () =>
      max(userDashboard?.dashboard?.widget_layouts.map((layout) => layout.y)),
    [userDashboard?.dashboard?.widget_layouts],
  );

  const getNewPanelLayout = (id, duplicateFromId) => {
    if (duplicateFromId) {
      const from = userDashboard.dashboard.widget_layouts.find(
        (pl) => pl.i === duplicateFromId,
      );
      return [
        ...userDashboard.dashboard.widget_layouts,
        {
          ...getNewWidgetCoordinates({
            widgetId: 'custom',
            fromWidgetLayout: from,
          }),
          i: id,
        },
      ];
    }
    return [
      ...userDashboard.dashboard.widget_layouts,
      { ...getNewWidgetCoordinates({ widgetId: 'custom' }), i: id },
    ];
  };

  const onSaveCustomMetricsPanel = async (
    from,
    { payload, postSave = null },
  ) => {
    try {
      const newPanel = payload;
      if (!selectedPanel.panel.id) {
        const newUserDashboard = {
          ...userDashboard,
          dashboard: {
            ...userDashboard.dashboard,
            widgets: [
              ...userDashboard.dashboard.widgets,
              { widget: 'custom', ...newPanel },
            ],
            widget_layouts: getNewPanelLayout(newPanel.id),
          },
        };
        setUserDashboard(newUserDashboard);
      } else {
        const newUserDashboard = {
          ...userDashboard,
          dashboard: {
            ...userDashboard.dashboard,
            widgets: userDashboard.dashboard.widgets.map((widget) =>
              widget.id === newPanel.id ? newPanel : widget,
            ),
          },
        };
        setUserDashboard(newUserDashboard);
      }

      setSelectedPanel({ panel: newPanel });

      if (postSave) {
        await postSave();
      } else {
        toast.success('Saved');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const onDuplicateCustomMetricsPanel = async (duplicateFrom) => {
    const panel = {
      ...duplicateFrom,
      id: shortId(),
      title: duplicateFrom ? `${duplicateFrom.title} - Copy` : 'New Panel',
    };
    const newUserDashboard = {
      ...userDashboard,
      dashboard: {
        ...userDashboard.dashboard,
        widgets: [
          ...userDashboard.dashboard.widgets,
          { widget: 'custom', ...panel },
        ],
        widget_layouts: getNewPanelLayout(panel.id, duplicateFrom.id),
      },
    };
    setUserDashboard(newUserDashboard);
  };

  const onVariableChange = (value, variable) => {
    setVariableValue(
      varaibleValue.map((v) => (v.id === variable.id ? { ...v, value } : v)),
    );
  };

  const onDelete = (id) => {
    alert.confirm({
      description: 'Are you sure you want to delete this widget?',
      onConfirm: () => {
        const newUserDashboard = {
          ...userDashboard,
          dashboard: {
            ...userDashboard.dashboard,
            widgets: userDashboard.dashboard.widgets.filter(
              (widget) => widget.id !== id,
            ),
            widget_layouts: userDashboard.dashboard.widget_layouts.filter(
              (widgetLayout) => widgetLayout.i !== id,
            ),
          },
        };
        setUserDashboard(newUserDashboard);
        analyticsTrack('Widget Deleted');
      },
    });
  };

  const prevLayouts = usePrevious(userDashboard?.dashboard?.widget_layouts);

  const doOverlap = (widgetLayout1, widgetLayout2) => {
    const box1Right = widgetLayout1.x + widgetLayout1.w;
    const box1Bottom = widgetLayout1.y + widgetLayout1.h;
    const box2Right = widgetLayout2.x + widgetLayout2.w;
    const box2Bottom = widgetLayout2.y + widgetLayout2.h;

    return (
      widgetLayout1.x < box2Right &&
      box1Right > widgetLayout2.x &&
      widgetLayout1.y < box2Bottom &&
      box1Bottom > widgetLayout2.y
    );
  };

  const getNewWidgetCoordinates = ({
    widgetId,
    fromWidgetLayout = null,
    existingCoordinates,
  }) => {
    const newWidgetLayout = fromWidgetLayout ?? {
      ...DEFAULT_LAYOUT_CONFIG[widgetId],
    };
    const sortedWidgetLayouts = sortBy(
      existingCoordinates || userDashboard.dashboard.widget_layouts,
      ['y', 'x'],
    );
    for (let row = 0; row < 12; row += 0.05) {
      for (let col = 0; col < 12; col++) {
        const proposedLayout = { ...newWidgetLayout, x: col, y: row };

        if (
          sortedWidgetLayouts.every(
            (sortedWidgetLayout) =>
              !doOverlap(proposedLayout, sortedWidgetLayout),
          )
        ) {
          return proposedLayout;
        }
      }
    }
    return {
      ...DEFAULT_LAYOUT_CONFIG[widgetId],
      y: parseInt(lastRow + 1 || 0),
    };
  };

  const onSelectWidget = async (widgetId) => {
    switch (widgetId) {
      case 'lambda_invocations':
      case 'lambda_errors':
        setVariableValue(variables);
        setLambdaWidget(widgetId);
        setVariableDialog(true);
        break;
      case 'custom':
        setShowWizard(true);
        break;
      default:
        const id = shortId();
        const config = widgets_config[widgetId];
        if (widgetId === 'metrics_points_count') {
          config.node_configs[0].filters = [
            {
              field: 'workspaceId',
              type: 'string',
              operator: '=',
              value: workspace.workspace.id,
            },
          ];
        }
        const newUserDashboard = {
          ...userDashboard,
          dashboard: {
            ...userDashboard.dashboard,
            widgets: [...userDashboard.dashboard.widgets, { id, ...config }],
            widget_layouts: [
              ...userDashboard.dashboard.widget_layouts,
              { ...getNewWidgetCoordinates({ widgetId: widgetId }), i: id },
            ],
          },
        };
        setUserDashboard(newUserDashboard);
        analyticsTrack('Widget Added');
        toast.success('Widget added successfully');
    }
  };

  const saveLambdaWidget = () => {
    const id = shortId();
    const config = widgets_config[lambdaWidget];
    const newUserDashboard = {
      ...userDashboard,
      dashboard: {
        ...userDashboard.dashboard,
        widgets: [
          ...userDashboard.dashboard.widgets,
          { id, ...resolveTemplateVariables(config, varaibleValue) },
        ],
        widget_layouts: [
          ...userDashboard.dashboard.widget_layouts,
          { ...getNewWidgetCoordinates({ widgetId: lambdaWidget }), i: id },
        ],
      },
    };
    setUserDashboard(newUserDashboard);
    toast.success('Widget added successfully');
    setVariableDialog(false);
  };

  const onLayoutChange = async (currentLayout) => {
    const isSameLayout = isEqual(prevLayouts, currentLayout);
    if (isSameLayout) {
      return;
    }
    const newUserDashboard = {
      ...userDashboard,
      dashboard: {
        ...userDashboard.dashboard,
        widget_layouts: currentLayout,
      },
    };
    setUserDashboard(newUserDashboard);
  };

  const onBackgroundSave = async (newUserDashboard) => {
    if (!newUserDashboard) {
      return;
    }
    if (newUserDashboard.id) {
      await updateUserDashboard({
        variables: {
          id: newUserDashboard.id,
          dashboard: newUserDashboard.dashboard,
        },
      });
    } else {
      const res = await saveUserDashboard({
        variables: {
          dashboard: newUserDashboard.dashboard,
          workspaceId: workspace.workspace.id,
          userId: user.id,
        },
      });
    }
  };

  const onImport = async (panels) => {
    setShowWizard(false);
    setAutoSelectTemplate(null);
    if (!panels) {
      setSelectedPanel(NEW_PANEL);
      return;
    }

    let newLayout = userDashboard?.dashboard?.widget_layouts || [];
    const importedPanels = panels.map((panel) => ({
      ...panel,
      id: shortId(),
      widget: 'custom',
    }));

    importedPanels.forEach((panel) => {
      const coordinates = getNewWidgetCoordinates({
        widgetId: 'custom',
        existingCoordinates: newLayout,
      });
      const layout = { i: panel.id, ...coordinates };
      newLayout = [...newLayout, layout];
    });

    const newUserDashboard = {
      ...userDashboard,
      dashboard: {
        ...userDashboard.dashboard,
        widgets: [...userDashboard.dashboard.widgets, ...importedPanels],
        widget_layouts: newLayout,
      },
    };
    setUserDashboard(newUserDashboard);
    toast.success('Widget imported successfully');
  };

  const onSave = async () => {
    await onBackgroundSave(userDashboard);
    setEditing(false);
    analyticsTrack('Homepage Saved');
    toast.success('Saved');
  };

  const onCloseNewPanelWizard = () => {
    setShowWizard(false);
    setAutoSelectTemplate(null);
  };

  usePageEvent('Home', {
    section: 'Home',
    flow: 'Home',
  });

  //grid layout children should be memoized for performance - https://github.com/react-grid-layout/react-grid-layout#performance
  const gridChildren = useMemo(() => {
    const widgetComponentMap = {
      recent_issues: ({ id }) => (
        <RecentIssues
          title="Recent Issues"
          startTime={startTime}
          endTime={endTime}
          isEditable={isEditing}
          onDelete={() => onDelete(id)}
        />
      ),
      active_alarms: ({ id }) => (
        <ActiveAlarms
          title="Active Alarms"
          startTime={startTime}
          endTime={endTime}
          isEditable={isEditing}
          onDelete={() => onDelete(id)}
        />
      ),
      aws_cost_graph: ({ id }) => (
        <MonthlyBarData
          accountId={forecastAccountId}
          setAccountId={setForecastAccountId}
          accountsResp={accountsResp}
          isEditable={isEditing}
          onDelete={() => onDelete(id)}
        />
      ),
      aws_resources_map: ({ id }) => (
        <Map
          clickedCountry={clickedCountry}
          setClickedCountry={setClickedCountry}
          clickedRegion={clickedRegion}
          setClickedRegion={setClickedRegion}
          setAccountId={setMapAccountId}
          accountId={mapAccountId}
          accountsResp={accountsResp}
          isEditable={isEditing}
          onDelete={() => onDelete(id)}
        />
      ),
      aws_cost_breakdown: ({ id }) => (
        <CostPerService
          setAccountId={setCostAccountId}
          accountId={costAccountId}
          accountsResp={accountsResp}
          isEditable={isEditing}
          onDelete={() => onDelete(id)}
        />
      ),
      services_summary: ({ id }) => (
        <TraceSummary
          timestamp={timestamp}
          hours={timeRange}
          isEditable={isEditing}
          onDelete={() => onDelete(id)}
        />
      ),
      custom: (panel) => (
        <Panel
          dshTimeRange={timeRange + 'h'}
          dashboardTimeConfig={userDashboard.dashboard.time_config}
          panel={panel}
          dashboardId={userDashboard.id}
          showPanelEdit={isEditing}
          onDeletePanel={() => onDelete(panel.id)}
          setSelectedPanel={() => {
            setSelectedPanel({ panel });
          }}
          onDuplicatePanel={() => onDuplicateCustomMetricsPanel(panel)}
          defaultRegion={userDashboard.dashboard.default_region}
          isEditable={true}
          isViewMode={!isEditing}
          workspaceId={
            panel.widget === 'metrics_points_count' && defaultWorkspaceId
          }
        />
      ),
    };
    const arr = userDashboard?.dashboard?.widgets || [];
    return arr.map((obj) => {
      let child = null;
      const componentFn = widgetComponentMap[obj.widget];
      if (!componentFn) {
        child = widgetComponentMap['custom'](obj);
      } else {
        child = componentFn(obj);
      }

      return <GridLayoutChild key={obj.id}>{child}</GridLayoutChild>;
    });
  }, [
    userDashboard,
    isEditing,
    startTime,
    endTime,
    timeRange,
    timestamp,
    accountsResp,
    forecastAccountId,
    mapAccountId,
    costAccountId,
  ]);

  return (
    <>
      <PageContainer loading={loading} title="Home" sx={{ overflow: 'clip' }}>
        <PageContainer.PageHeader
          sx={{ alignItems: 'center' }}
          primary={`Welcome back, ${getFirstName(user.name)}!`}
          primaryTypographyProps={{
            variant: 'body',
            color: 'text.secondary',
            mb: 0,
          }}
          actions={
            <Stack key={0} direction="row" spacing={1} alignItems="center">
              {isEditing ? (
                <>
                  <IconButton
                    size="small"
                    title="Cancel"
                    icon={<UndoIcon />}
                    onClick={() => {
                      data?.rows[0] && setUserDashboard(data?.rows[0]);
                      setEditing(false);
                    }}
                  />
                  <IconButton
                    size="small"
                    title="Save"
                    icon={<SaveIcon />}
                    onClick={onSave}
                  />
                  <IconButton
                    size="small"
                    title="Add Widget"
                    icon={<AddchartIcon />}
                    onClick={() => {
                      setShowAddWidgetModal(true);
                      analyticsPage('Add Widget', {
                        section: 'Add Widget',
                        flow: 'Edit Home',
                      });
                    }}
                  />
                </>
              ) : (
                <IconButton
                  size="small"
                  title="Edit Dashboard"
                  icon={<EditIcon />}
                  onClick={() => setEditing(true)}
                />
              )}
              <TimeRangeSelect onChange={setTimeRange} value={timeRange} />
            </Stack>
          }
        />
        <>
          <WidthProvider debounce={30}>
            {(width) => (
              <Responsive
                className="layout"
                width={width}
                isDraggable={isEditing}
                isRearrangeable={isEditing}
                isResizable={isEditing}
                draggableHandle={`.${classes.gridItemTitle}`}
                breakpoints={{ lg: LG, md: MD, sm: SM, xs: XS, xxs: XXS }}
                cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                layouts={{
                  lg: userDashboard?.dashboard?.widget_layouts,
                  md: userDashboard?.dashboard?.widget_layouts,
                  sm: userDashboard?.dashboard?.widget_layouts,
                  xs: userDashboard?.dashboard?.widget_layouts,
                  xxs: userDashboard?.dashboard?.widget_layouts,
                }}
                useCSSTransforms={false}
                onDragStop={onLayoutChange}
                onResizeStop={onLayoutChange}
              >
                {gridChildren}
              </Responsive>
            )}
          </WidthProvider>
          {showAddWidgetModal && (
            <AddWidgetModal
              onClose={() => setShowAddWidgetModal(false)}
              onSelect={onSelectWidget}
            />
          )}
          {selectedPanel && (
            <EditPanelModal
              panel={selectedPanel.panel}
              closeModal={() => setSelectedPanel(null)}
              onSavePanel={onSaveCustomMetricsPanel}
              defaultRegion={DEFAULT_REGION}
            />
          )}
        </>
      </PageContainer>
      {showWizard && (
        <NewPanelWizard
          onImport={onImport}
          onClose={onCloseNewPanelWizard}
          defaultRegion={userDashboard.dashboard.default_region}
          autoSelectTemplate={autoSelectTemplate}
          noVariablesInPanel
        />
      )}
      <Dialog open={variableDialog} fullWidth maxWidth="sm">
        <DialogTitle>Set Widget Variables</DialogTitle>
        <DialogContent>
          <VariablesBar
            onVariableChange={onVariableChange}
            variables={varaibleValue}
            defaultRegion={DEFAULT_REGION}
            direction="column"
            timeRange={timeRange}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={saveLambdaWidget}>
            Save
          </Button>
          <Button variant="text" onClick={() => setVariableDialog(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Home;
