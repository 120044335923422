import ListPanel from '../../ListPanel';
import { ActiveAlarmsView } from './ActiveAlarmsView';
import { CONFIG as panelConfig } from '../../PanelSettings/Panel';
import { CONFIG as timeConfig } from '../../PanelSettings/TimeRangeOptions';
import { QUERY_TYPE } from 'modules/alarms/utils';
import { DATA_SET } from 'constants/data-sources';

export const CONFIG = {
  id: 'alarms',
  settings: {
    general: [panelConfig, timeConfig],
    overrides: null,
  },
  name: 'Alarms List',
  description: 'Alarms list',
  multiNodes: false,
  defaultNodeConfig: {
    query_type: QUERY_TYPE.NON_AGG,
    dataset: DATA_SET.ALARMS,
    limit: 10,
    page: 1,
  },
  showTableView: false,
  configurableSource: false,
};

const AlarmsListPanel = ({ alarms, error, ...listProps }) => (
  <ListPanel {...listProps}>
    <ActiveAlarmsView alarms={alarms} error={error} />
  </ListPanel>
);

export default AlarmsListPanel;
