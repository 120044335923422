import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export const AXIS_PLACEMENT = {
  auto: {
    value: 'auto',
    name: 'Auto',
  },
  left: {
    value: 'left',
    name: 'Left',
  },
  right: {
    value: 'right',
    name: 'Right',
  },
};

const AxisPlacement = ({ property, idx, setFieldValue, prefix }) => {
  return (
    <ToggleButtonGroup
      exclusive
      variant="outlined"
      size="small"
      value={property?.value || AXIS_PLACEMENT.auto.value}
      onChange={(e, v) => {
        setFieldValue(`${prefix}.properties.${idx}.value`, v);
      }}
    >
      {Object.values(AXIS_PLACEMENT).map(({ name, value }, index) => {
        return (
          <ToggleButton disableRipple key={index} value={value}>
            {name}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

export const CONFIG = {
  id: 'axisPlacement',
  name: 'Axis Placement',
  description: 'Customize axis placement',
  Component: AxisPlacement,
};

export default AxisPlacement;
