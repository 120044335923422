import React from 'react';
import { useQuery } from '@apollo/client';
import { useSelector } from 'store';
import { GET_RECENT_ISSUES } from 'graphql/issues/queries';
import RecentIssuesView from 'modules/dashboards/components/panels/issues-list/RecentIssuesView';

const RecentIssues = ({
  title,
  endTime,
  startTime,
  isEditable,
  onDelete,
  serviceName,
  dataset,
}) => {
  const { workspace } = useSelector((s) => s.workspace);
  const { data, loading, error } = useQuery(GET_RECENT_ISSUES, {
    variables: {
      query: {
        workspace_id: { _eq: workspace.id },
        status: { _eq: 'open' },
        last_occurrence_at: {
          _gte: startTime.toISOString(),
          _lte: endTime.toISOString(),
        },
        service_name: serviceName ? { _eq: serviceName } : undefined,
        dataset: dataset ? { _eq: dataset } : undefined,
      },
      orderBy: { created_at: 'desc' },
      limit: 5,
      offset: 0,
    },
  });

  return (
    <RecentIssuesView
      title={title}
      issues={data?.rows}
      total={data?.aggregate.aggregate.count}
      loading={loading}
      error={error}
      isEditable={isEditable}
      onDelete={onDelete}
    />
  );
};

export default RecentIssues;
