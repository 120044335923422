import React from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReactComponent as RecentIssueIcon } from 'assets/icons/recent-issue-icon.svg';
import DashboardCard from 'components/DashboardCard';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from 'components/IconButton';

export const RecentIssuesView = ({
  title,
  issues,
  total,
  loading,
  error,
  isEditable,
  onDelete,
}) => (
  <DashboardCard
    keyField="id"
    title={
      title && (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="subtitle1">{title}</Typography>
          {isEditable && (
            <IconButton
              size="small"
              title="Delete Widget"
              icon={<DeleteIcon />}
              onClick={onDelete}
            />
          )}
        </Stack>
      )
    }
    loading={loading}
    error={error?.message}
    isDraggable
    isEditable={isEditable}
    rows={issues}
    getPrimaryText={(item) => item.error_type || `Unknown error`}
    getSecondaryText={(item) => item.message}
    renderActions={(item) => (
      <Box>
        <Typography textAlign="right">
          {item.count} {item.count === 1 ? 'time' : 'times'}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {moment(item.last_occurrence_at).fromNow(false)}
        </Typography>
      </Box>
    )}
    Icon={RecentIssueIcon}
    iconColor="error"
    count={total}
    getLink={(item) => `/${item.workspace_id}/issues/${item.id}`}
  />
);

export default RecentIssuesView;
