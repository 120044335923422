export const AggregationGroups = {
  HTTP_METHOD: {
    label: 'HTTP Method',
    key: 'http.method',
    type: 'string',
    dataset: 'traces',
  },
  HTTP_STATUS_CODE: {
    label: 'HTTP Status',
    key: 'http.status_code',
    type: 'number',
    dataset: 'traces',
  },
  SERVICE_NAME: {
    label: 'Service',
    key: 'serviceName',
    type: 'root',
    dataset: 'traces',
  },
  SEVERITY: {
    label: 'Severity',
    key: 'status.code',
    type: 'number',
    dataset: 'traces',
    formatLabel: (value) => {
      switch (value) {
        case '0':
          return 'Unset';
        case '1':
          return 'OK';
        case '2':
          return 'Error';
        default:
          return value;
      }
    },
  },
  FUNCTION: {
    label: 'Function',
    key: 'faas.name',
    type: 'string',
    dataset: 'traces',
  },
  DB_NAME: {
    label: 'Database Name',
    key: 'db.name',
    type: 'string',
    dataset: 'traces',
  },
};

export const AggregateFunctions = [
  'AVG',
  'SUM',
  'MIN',
  'MAX',
  'MEDIAN',
  'COUNT',
  'COUNT_DISTINCT',
  'P05',
  'P10',
  'P20',
  'P25',
  'P50',
  'P75',
  'P90',
  'P95',
  'P99',
  'FIRST',
  'LAST',
];

export const PromQLAggFuncs = ['RATE'];

export const DataTypes = ['string', 'number', 'bool'];

export const Operators = [
  {
    label: '=',
    value: '=',
  },
  {
    label: '!=',
    value: '!=',
  },
  {
    label: '>',
    value: '>',
  },
  {
    label: '<',
    value: '<',
  },
  {
    label: '>=',
    value: '>=',
  },
  {
    label: '<=',
    value: '<=',
  },
  {
    label: 'Exists',
    value: 'EXISTS',
    requiresValue: false,
  },
  {
    label: 'Not exists',
    value: 'NOT_EXISTS',
    requiresValue: false,
  },
  {
    label: 'In',
    value: 'IN',
  },
  {
    label: 'Not in',
    value: 'NOT_IN',
  },
  {
    label: 'Contains',
    value: 'CONTAINS',
  },
  {
    label: 'Not contains',
    value: 'NOT_CONTAINS',
  },
  {
    label: 'Starts with',
    value: 'STARTS_WITH',
  },
];

export const InternalFields = {
  Traces: [],
  Metrics: [],
  Logs: [
    { field: 'serviceName', type: 'root' },
    { field: 'severity', type: 'root' },
  ],
};
