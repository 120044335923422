import { parseInput } from 'components/DateRangePickerNew';
import { AggregateFunctions } from 'constants/aggregations';
import { GET_ALARMS } from 'graphql/alarms/queries';
import moment from 'moment';
import client from 'utils/apolloClient';
import { parseAlarmData } from './useAlarmData';
import partition from 'lodash/partition';
import { GET_RECENT_ISSUES } from 'graphql/issues/queries';

export const BADGE_COLOR = {
  Alerting: 'error',
  Firing: 'error',
  Pending: 'warning',
  Normal: 'success',
  NoData: 'default',
  Error: 'error',
};

export const ALARM_ENABLED_SERVICES = [];

export const getNextAlphabet = (alphabet) => {
  if (!alphabet) return 'A';
  const nextAlphabet = alphabet.charCodeAt(0) + 1;
  return String.fromCharCode(nextAlphabet);
};

export const parseInputSeconds = (val) => {
  const parsed = parseInput(val);
  if (!parsed) return 0;
  const { value, unit } = parsed;
  switch (unit) {
    case 's':
      return value;
    case 'm':
      return value * 60;
    case 'h':
      return value * 60 * 60;
    case 'd':
      return value * 60 * 60 * 24;
    case 'w':
      return value * 60 * 60 * 24 * 7;
    default:
      return 60;
  }
};

export const defaultAgg = {
  stat: AggregateFunctions[0],
  field: '',
  type: '',
};

export const secondsToString = (seconds) => {
  if (!seconds) return '';
  if (seconds < 60) {
    return `${seconds}s`;
  }
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return `${minutes}m`;
  }
  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return `${hours}h`;
  }
  return `${Math.floor(hours / 24)}d`;
};

export const getDimensionOptions = (dimensions) => {
  return dimensions
    ? Object.keys(dimensions).map((key) => {
        return {
          name: { label: key, value: key },
          values: dimensions[key].map((v) => ({
            label: v === '*' ? 'All' : v,
            value: v,
          })),
        };
      })
    : [];
};

export const getDimensionFilters = (dimensions) => {
  return (dimensions || []).reduce((acc, cur) => {
    if (cur.name && cur.values) {
      return {
        ...acc,
        [cur.name.value]: (cur.values || []).map((val) => val.value),
      };
    }
    return acc;
  }, {});
};

export const parsePanel = (panel) => {
  if (!panel) {
    return null;
  }
  return {
    ...panel,
    node_configs: (panel.node_configs || []).map((config) => ({
      ...config,
      dimensions: Array.isArray(config.dimensions)
        ? getDimensionFilters(config.dimensions)
        : config.dimensions,
    })),
  };
};

export const convertQueryBuilderToGql = (
  { filters = [], orderBy = [], limit, page, workspaceId, from, to },
  timeField,
) => {
  let query = {};
  let sortBy = {};

  filters.forEach(({ field, operator, value }) => {
    switch (operator) {
      case '=': {
        query[field] = { _eq: value };
        break;
      }
      case '!=': {
        query[field] = { _neq: value };
        break;
      }
      case '>': {
        query[field] = { _gt: value };
        break;
      }
      case '<': {
        query[field] = { _lt: value };
        break;
      }
      case '>=': {
        query[field] = { _gte: value };
        break;
      }
      case '<=': {
        query[field] = { _lte: value };
        break;
      }
      case 'EXISTS': {
        query[field] = { _is_null: false };
        break;
      }
      case 'NOT_EXISTS': {
        query[field] = { _is_null: true };
        break;
      }
      case 'IN': {
        query[field] = { _in: value };
        break;
      }
      case 'NOT_IN': {
        query[field] = { _nin: value };
        break;
      }
      case 'CONTAINS': {
        query[field] = { _ilike: value };
        break;
      }
      case 'NOT_CONTAINS': {
        query[field] = { _nilike: value };
        break;
      }
      case 'STARTS_WITH': {
        query[field] = { _starts_with: value };
        break;
      }
      default:
        break;
    }
  });

  query.workspace_id = { _eq: workspaceId };
  if (timeField) {
    query[timeField] = {
      _gte: moment(from).toJSON(),
      _lte: moment(to).toJSON(),
    };
  }

  orderBy.forEach(({ field, order }) => {
    sortBy[field] = order;
  });

  const pageSize = limit && parseInt(limit, 10);
  return {
    query,
    orderBy: sortBy,
    limit: pageSize,
    offset: pageSize && (page - 1) * pageSize,
  };
};

export const getAlarmsList = async (payload) => {
  // serviceName filter is applied on alarm_states.labels so separate out other filters that will be applied to alarm_rules.
  let serviceFilter;
  let stateFilter;
  const otherFilters = [];

  for (const filter of payload.filters) {
    if (filter.field === 'serviceName') {
      serviceFilter = filter;
    } else if (filter.field === 'state') {
      stateFilter = filter.value;
    } else {
      otherFilters.push(filter);
    }
  }

  const { from, to, limit, page, workspaceId } = payload;
  const variables = convertQueryBuilderToGql({
    workspaceId,
    filters: otherFilters,
  });

  if (serviceFilter) {
    variables.query.alarm_states = {
      labels: {
        _contains: [['serviceName', serviceFilter.value]],
      },
    };
  }

  variables.query.alarm_states = {
    last_evaluated_at: {
      _gte: moment(from).toJSON(),
      _lte: moment(to).toJSON(),
    },
  };

  const { data } = await client.query({
    query: GET_ALARMS({ useSubscription: false }),
    variables,
  });

  return parseAlarmData(data.alarm_rules, {
    limit: parseInt(limit, 10),
    page,
    state: stateFilter,
  });
};

export const getIssuesList = async (payload) => {
  const variables = convertQueryBuilderToGql(payload, 'last_occurrence_at');

  const { data } = await client.query({
    query: GET_RECENT_ISSUES,
    variables,
  });

  return data;
};

export const QUERY_TYPE = {
  AGG: 'qb',
  NON_AGG: 'raw',
  PROMQL: 'promql',
};