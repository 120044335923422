import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Field } from 'formik';
import Select from 'components/Form/Formik/FormikReactSelect';
import TextField from 'components/Form/Formik/FormikInput';
import { DATA_SOURCES } from 'constants/data-sources';
import { useQuery } from '@apollo/client';
import { GET_ACCOUNTS_BY_WORKSPACE_ID } from 'graphql/accounts/queries';
import { useSelector } from 'store';
import DataSet from './kloudmate/DataSet';
import DateRangePicker from 'components/DateRangePickerNew';
import {
  relTimeToSeconds,
  secondstoRelativeTime,
} from 'components/DateRangePickerNew/helper';

export const TIME_RANGE_OPTIONS = [
  { label: 'Last 5 minutes', value: 300 },
  { label: 'Last 10 minutes', value: 600 },
  { label: 'Last 15 minutes', value: 900 },
  { label: 'Last 30 minutes', value: 1800 },
  { label: 'Last 1 hour', value: 3600 },
  { label: 'Last 2 hours', value: 7200 },
  { label: 'Last 4 hours', value: 14400 },
  { label: 'Last 8 hours', value: 28800 },
  { label: 'Last 12 hours', value: 43200 },
  { label: 'Last 24 hours', value: 86400 },
  { label: 'Last 2 days', value: 172800 },
];

const QuerySourceConfig = ({
  value,
  name,
  setFieldValue,
  variableOptions,
  showAlarmTimePicker = true,
  panelType,
}) => {
  const workspaceId = useSelector((s) => s.workspace.workspace.id);
  const { data } = useQuery(GET_ACCOUNTS_BY_WORKSPACE_ID, {
    variables: {
      workspaceId,
    },
  });
  const accounts = data?.accounts || [];
  const isTablePanel = panelType === 'table';

  useEffect(() => {
    if (!value.datasourceId) {
      setFieldValue(`${name}.datasourceId`, 'kloudmate');
    }
  }, [accounts]);

  // alarm's timerange format is relative seconds from now
  // eg: { from: 600, to: 0 } (last 10 mins)
  // convert it to date picker format i.e. now-10m:now
  const time_range = useMemo(() => {
    if (!showAlarmTimePicker) {
      return;
    }
    const from = secondstoRelativeTime(value.time_range.from);
    const to = secondstoRelativeTime(value.time_range.to);
    return `${from}:${to}`;
  }, [value.time_range]);

  const datasourceOptions = isTablePanel
    ? []
    : accounts.map((account) => ({
        label: `${account.provider}/${account.name}`,
        value: account.id,
        groupBy: variableOptions?.length ? 'Data Sources' : undefined,
      }));

  datasourceOptions.unshift({
    label: 'OpenTelemetry',
    value: DATA_SOURCES.KLOUDMATE,
    groupBy: variableOptions?.length ? 'Data Sources' : undefined,
  });

  const onTimeChange = (val) => {
    const [from, to] = val.value.split(':');

    // convert date picker's timerange format to alarms timerange format(seconds from now)
    // eg: now-40m:now-10m becomes { from: 2400, to: 600 }
    const time_range = {
      from: relTimeToSeconds(!to ? `-${from}` : from),
      to: relTimeToSeconds(to),
    };
    setFieldValue(`${name}.time_range`, time_range);
  };

  return (
    <Grid container columnSpacing={2}>
      <Grid item md={showAlarmTimePicker ? 2 : 3}>
        <Field
          fullWidth
          transformValue
          size="small"
          margin="none"
          name={`${name}.datasourceId`}
          component={Select}
          options={(variableOptions || []).concat(datasourceOptions)}
        />
      </Grid>
      {value.datasourceId === DATA_SOURCES.KLOUDMATE && (
        <Grid item md={2}>
          <DataSet
            name={name}
            queryOptions={value}
            updateQueryOptions={setFieldValue}
          />
        </Grid>
      )}
      {showAlarmTimePicker && (
        <Grid item md={2}>
          <DateRangePicker
            value={{
              value: time_range,
              tz: 'local',
            }}
            onChange={onTimeChange}
            variant="outlined"
            size="small"
            sx={{
              fontWeight: 400,
            }}
            fullWidth
            hideTzToggle
            hideCustomTimeToggle
            showReadableLabel
            onlyRelativeTime
            hideIcon
          />
        </Grid>
      )}
      {!isTablePanel && (
        <Grid item md={4}>
          <Field
            sx={{ width: 100 }}
            size="small"
            name={`${name}.interval`}
            placeholder={'Interval (60s)'}
            margin={'none'}
            component={TextField}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default QuerySourceConfig;
