import { gql } from '@apollo/client';
import {
  alarmFields,
  alarmStateFields,
  alarmStateHistoryFields,
} from './fragments';

export const GET_ALARMS = ({ useSubscription = true } = {}) => gql`
  ${
    useSubscription ? 'subscription' : 'query'
  } getAlarmRules($query: alarm_rules_bool_exp!, $orderBy: [alarm_rules_order_by!]) {
    alarm_rules(where: $query, order_by: $orderBy) {
      id
      workspace_id
      name
      description
      tags
      disabled
      states: alarm_states(order_by: { current_state: asc }) {
        ...AlarmState
      }
    }
  }
  ${alarmStateHistoryFields}
  ${alarmStateFields}
`;

export const GET_TOP_ALARMS = gql`
  subscription getAlarms($workspaceId: String!) {
    rows: alarm_states(
      where: {
        current_state: { _in: ["Alerting"] }
        alarm: { workspace_id: { _eq: $workspaceId }, disabled: { _eq: false } }
      }
      limit: 5
      order_by: { current_state_since: desc }
    ) {
      id
      alarm_id
      current_state_since
      alarm {
        id
        workspace_id
        name
        description
      }
    }
  }
  ${alarmFields}
`;

export const GET_ALARMS_LIST = gql`
  query getAlarms($workspaceId: String!) {
    rows: alarm_rules(where: { workspace_id: { _eq: $workspaceId } }) {
      id
      name
    }
  }
`;

export const GET_ALARM_BY_ID = gql`
  query getAlarmById($id: String!) {
    alarm: alarm_rules_by_pk(id: $id) {
      ...AlarmRule
    }
  }
  ${alarmFields}
`;

export const GET_ALARM_DETAILS = gql`
  query getAlarmById($id: String!) {
    alarm: alarm_rules_by_pk(id: $id) {
      ...AlarmRule
      states: alarm_states(order_by: { current_state: asc }) {
        ...AlarmState
      }
    }
  }
  ${alarmFields}
  ${alarmStateFields}
  ${alarmStateHistoryFields}
`;

export const DELETE_ALARM = gql`
  mutation deleteAlarm($id: String!) {
    delete_alarm_rules_by_pk(id: $id) {
      ...AlarmRule
    }
  }
  ${alarmFields}
`;

export const UPDATE_ALARM = gql`
  mutation updateAlarm($id: String!, $alarm: alarm_rules_set_input!) {
    update_alarm_rules_by_pk(pk_columns: { id: $id }, _set: $alarm) {
      ...AlarmRule
    }
  }
  ${alarmFields}
`;

export const GET_ALARM_STATES = gql`
  subscription getAlarmStates($alarmId: String!) {
    alarm_states(
      where: { alarm_id: { _eq: $alarmId } }
      order_by: { state_updated_at: desc }
    ) {
      ...AlarmState
    }
  }
  ${alarmStateFields}
`;

export const GET_STATE_HISTORY = gql`
  query getStateHistory($alarmId: String!) {
    rows: alarm_states_history(
      where: { alarm_id: { _eq: $alarmId } }
      limit: 100
      order_by: { created_at: desc }
    ) {
      ...AlamStateHistory
    }
  }
  ${alarmStateHistoryFields}
`;

export const QUERY_STATE_HISTORY = gql`
  query queryStateHistory($query: alarm_states_history_bool_exp) {
    rows: alarm_states_history(
      where: $query
      limit: 100
      distinct_on: created_at
    ) {
      id
      created_at
      alarm {
        id
        name
      }
    }
  }
`;

export const GET_ALARM_STATE_LABELS = gql`
  query getAlarmLabels($query: alarm_states_bool_exp!) {
    rows: alarm_states(where: $query) {
      labels
    }
  }
`;

export const GET_ALARM_COLUMN_VALUES = (column) => gql`
  query getAlarmColumnValues($query: alarm_rules_bool_exp!, $column: [alarm_rules_select_column!]) {
    rows: alarm_rules(where: $query, distinct_on: $column) {
      ${column || 'id'}
    }
  }
`;
