import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import KloudMateQueryForm from './KloudMateQueryForm';
import { parseValue } from 'components/DateRangePickerNew';
import moment from 'moment';
import TimeSeriesPanel from 'modules/dashboards/components/panels/timeseries';

const KloudMateForm = ({
  name,
  values,
  account,
  onChange,
  result,
  displayResult,
  compact,
  variableOptions,
  timeRange,
  workspace,
  onChangeUnit,
  resolvedVariablesValues,
}) => {
  const { from, to } = useMemo(() => {
    if (typeof timeRange === 'string') {
      return parseValue(timeRange);
    }
    return {
      from: moment.utc().subtract(timeRange.from, 'seconds').valueOf(),
      to: moment.utc().subtract(timeRange.to, 'seconds').valueOf(),
    };
  }, [timeRange]);

  return (
    <Box>
      <KloudMateQueryForm
        workspace={workspace}
        values={values}
        name={name}
        account={account}
        onChange={onChange}
        compact={compact}
        variableOptions={variableOptions}
        from={from}
        to={to}
        onChangeUnit={onChangeUnit}
        resolvedVariablesValues={resolvedVariablesValues}
      />
      {displayResult && result && (
        <Box sx={{ overflowX: 'clip' }}>
          <TimeSeriesPanel
            timeRange={values.time_range}
            data={result.frames}
            height={200}
          />
        </Box>
      )}
    </Box>
  );
};

export default KloudMateForm;
