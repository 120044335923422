import React from 'react';
import Box from '@mui/material/Box';
import CloudwatchQueryForm from './CloudwatchQueryForm';
import TimeSeriesPanel from 'modules/dashboards/components/panels/timeseries';

const CloudwatchForm = ({
  name,
  values,
  onChange,
  result,
  displayResult,
  compact,
  variableOptions,
  timeRange,
  resolvedVariablesValues,
}) => {
  return (
    <Box>
      <CloudwatchQueryForm
        values={values}
        name={name}
        onChange={onChange}
        compact={compact}
        variableOptions={variableOptions}
        timeRange={timeRange}
        resolvedVariablesValues={resolvedVariablesValues}
      />
      {displayResult && result && (
        <Box sx={{ overflowX: 'clip' }}>
          <TimeSeriesPanel
            timeRange={values.time_range}
            data={result.frames}
            height={200}
          />
        </Box>
      )}
    </Box>
  );
};

export default CloudwatchForm;
