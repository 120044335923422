import { Field, FieldArray, useFormikContext } from 'formik';
import Select from 'components/Form/Formik/FormikReactSelect';
import TextField from 'components/Form/Formik/FormikInput';
import CloseIcon from '@mui/icons-material/Close';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { getStatPanelLabel } from '../../panels/utils';

const ID = 'rename';

const Rename = ({ name, frames = [] }) => {
  const { values } = useFormikContext();

  const columns = frames.map((frame) => {
    const value = getStatPanelLabel(frame);
    return {
      label: value,
      value,
    };
  });

  const rename = values[name][ID] || [];

  return (
    <FieldArray name={`${name}.${ID}`}>
      {({ push, remove }) => {
        return (
          <Grid container spacing={1}>
            {!rename.length && (
              <Grid item xs={12}>
                <Typography color="text.secondary" textAlign={'center'}>
                  No data found
                </Typography>
              </Grid>
            )}
            {rename.map((_, idx) => (
              <Grid container item sx={12} spacing={1} alignItems={'center'}>
                <Grid item xs={5}>
                  <Field
                    transformValue
                    margin="none"
                    name={`${name}.${ID}.${idx}.rename`}
                    component={Select}
                    label={'Rename'}
                    options={columns}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    fullWidth
                    margin="none"
                    name={`${name}.${ID}.${idx}.to`}
                    label="To"
                    component={TextField}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    icon={<CloseIcon />}
                    size="small"
                    onClick={() => remove(idx)}
                  />
                </Grid>
              </Grid>
            ))}
            <Grid item mt={1} xs={12} textAlign={'end'}>
              <Button
                size="small"
                variant="contained"
                color="neutral"
                onClick={() => push({ rename: '', to: '' })}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        );
      }}
    </FieldArray>
  );
};

export const CONFIG = {
  id: ID,
  name: 'Rename',
  description: 'Rename stat panels',
  Component: Rename,
  default: {
    [ID]: [],
  },
};

export default Rename;
