import React from 'react';
import Stack from '@mui/material/Stack';
import Switch from 'components/Form/Formik/FormikSwitch';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useFormikContext, Field } from 'formik';
import Select from 'components/Form/Formik/FormikReactSelect';
import Typography from '@mui/material/Typography';

export const LEGEND_MODE = {
  list: { value: 'list', label: 'List' },
  table: { value: 'table', label: 'Table' },
};

export const LEGEND_PLACEMENT = {
  right: { value: 'right', label: 'Right' },
  bottom: { value: 'bottom', label: 'Bottom' },
};

export const LEGEND_VALUES = {
  formattedValue: { value: 'formattedValue', label: 'Value' },
  formattedPercent: { value: 'formattedPercent', label: 'Percentage' },
};

const ID = 'legend';

const Legend = ({ name }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <Stack spacing={1}>
      <Field
        label="Show legend"
        labelPlacement="end"
        name={`${name}.${ID}.show`}
        size="small"
        formControlLabelProps={{
          componentsProps: {
            typography: {
              color: 'text.secondary',
            },
          },
        }}
        component={Switch}
      />
      <Stack direction="row" alignItems={'center'} spacing={1}>
        <Typography gutterBottom variant="body1" color="text.secondary">
          Mode
        </Typography>
        <ToggleButtonGroup
          exclusive
          variant="outlined"
          size="small"
          value={values[name][ID].mode}
          onChange={(e, v) => {
            setFieldValue(`${name}.${ID}.mode`, v);
          }}
        >
          {Object.values(LEGEND_MODE).map(({ label, value }, index) => {
            return (
              <ToggleButton disableRipple key={index} value={value}>
                {label}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Stack>
      <Stack direction="row" alignItems={'center'} spacing={1}>
        <Typography gutterBottom variant="body1" color="text.secondary">
          Placement
        </Typography>
        <ToggleButtonGroup
          exclusive
          variant="outlined"
          size="small"
          value={values[name][ID].placement}
          onChange={(e, v) => {
            setFieldValue(`${name}.${ID}.placement`, v);
          }}
        >
          {Object.values(LEGEND_PLACEMENT).map(({ label, value }, index) => {
            return (
              <ToggleButton disableRipple key={index} value={value}>
                {label}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Stack>
      <Field
        fullwidth
        isMulti
        size="small"
        label={'Legend Values'}
        name={`${name}.${ID}.values`}
        component={Select}
        transformValue
        options={Object.values(LEGEND_VALUES)}
      />
    </Stack>
  );
};

export const CONFIG = {
  id: ID,
  name: 'Legend',
  description: 'Customize Legend',
  Component: Legend,
  default: {
    [ID]: {
      show: true,
      mode: LEGEND_MODE.list.value,
      values: [LEGEND_VALUES.formattedPercent.value],
      placement: LEGEND_PLACEMENT.right.value,
    },
  },
};

export default Legend;
