import React, { useState, useMemo, useRef } from 'react';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Row from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Pagination from '@mui/material/Pagination';
import TextField from 'components/Form/TextInput';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Spin from 'components/Spin';
import EnhancedTableHead from './TableHead';
import TableRow from './TableRow';
import { pxToRem } from 'theme/typography';

const searchData = (data, columns, text) => {
  if (!text) return data;
  const trimmedSearchText = text.trim();
  if (!trimmedSearchText) return data;
  return data.filter((row) => {
    return columns
      .filter((columnDef) => {
        return columnDef.searchable === undefined ? true : columnDef.searchable;
      })
      .some((columnDef) => {
        if (columnDef.customFilterAndSearch) {
          return !!columnDef.customFilterAndSearch(
            trimmedSearchText,
            row,
            columnDef,
          );
        } else if (columnDef.dataField) {
          const value = get(row, columnDef.dataField);
          if (value) {
            return value
              .toString()
              .toLowerCase()
              .includes(trimmedSearchText.toLowerCase());
          }
        }
        return false;
      });
  });
};

const DataTable = ({
  remote,
  data,
  columns = [],
  defaultSorted,
  selectRow,
  rowEvents,
  keyField = 'id',
  onTableChange,
  pagination,
  search,
  size,
  loading,
  bordered,
  hover = true,
  emptyMessage,
  renderChildData,
  tableContainerStyle,
  rowStyle,
  searchBarProps = {},
  stickyHeader = false,
  paginationProps = {},
}) => {
  const [sort, setSort] = useState(
    defaultSorted || { sortField: '', sortOrder: '' },
  );

  const [page, setPage] = React.useState(pagination?.page ?? 1);
  const [rowsPerPage, setRowsPerPage] = React.useState(pagination?.limit ?? 10);
  const [query, setQuery] = useState('');
  const currentFilters = useRef({});

  const onFilterChange = (field, value, initialize = false) => {
    const _currentFilter = { ...currentFilters.current };
    const newFilters = { ..._currentFilter, [field]: { filterVal: value } };
    if (!value) {
      delete _currentFilter[field];
    }
    currentFilters.current = _currentFilter;
    if (!initialize) {
      onTableChange('filter', { filters: newFilters });
    }
  };

  const isSelected = (row) =>
    selectRow.selected.indexOf(get(row, keyField)) !== -1;

  const handleRequestSort = (property) => {
    const newSort = { sortField: property };
    if (sort.sortField === property) {
      newSort.sortOrder = sort.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      newSort.sortOrder = 'asc';
    }
    setSort(newSort);
    onTableChange('sort', newSort);
  };

  const handleClick = (e, row) => {
    if (rowEvents?.onClick) {
      rowEvents.onClick(e, row);
    }
  };

  const handleCheckBox = (row) => (e) => {
    selectRow.onSelect(row, e.target.checked);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onTableChange && onTableChange('pagination', { page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    const limit = parseInt(event.target.value, 10);
    setRowsPerPage(limit);
    setPage(1);
    onTableChange && onTableChange('pagination', { limit, page });
  };
  const handleChangeSearch = (event) => {
    setPage(1);
    setQuery(event.target.value);
  };

  const filteredData = useMemo(() => {
    if (data) {
      setPage(1);
      return searchData(data, columns, query);
    }
    return [];
  }, [query, data]);

  const slicedRows = remote
    ? data
    : pagination
    ? filteredData.slice(
        (page - 1) * rowsPerPage,
        (page - 1) * rowsPerPage + rowsPerPage,
      )
    : filteredData;

  const pageCount = Math.ceil(
    (pagination?.total ?? filteredData.length) / rowsPerPage,
  );

  const selectionEnabled = selectRow?.onSelect;

  return (
    <>
      {search && (
        <TextField
          size="small"
          value={query}
          onChange={handleChangeSearch}
          endAdornment={<SearchIcon sx={{ color: 'text.icon' }} />}
          placeholder="Search"
          sx={{ pl: 2 }}
          style={{
            minWidth: 250,
          }}
          {...searchBarProps}
        />
      )}
      <Spin spinning={loading}>
        <TableContainer sx={tableContainerStyle}>
          <Table size={size} stickyHeader={stickyHeader}>
            <EnhancedTableHead
              childrenEnabled={!!renderChildData}
              selectionEnabled={selectionEnabled}
              rows={filteredData}
              numSelected={selectRow?.selected.length}
              columns={columns}
              sortColumn={sort.sortField}
              sortOrder={sort.sortOrder}
              onSelectAllClick={selectRow?.onSelectAll}
              onRequestSort={handleRequestSort}
              filters={currentFilters.current}
              onFilterChange={onFilterChange}
            />
            <TableBody>
              {slicedRows?.map((row, index) => {
                const isItemSelected = selectRow ? isSelected(row) : false;
                return (
                  <TableRow
                    index={index}
                    hover={hover}
                    key={keyField ? get(row, keyField) : index}
                    row={row}
                    keyField={keyField}
                    renderChildren={renderChildData}
                    handleClick={
                      rowEvents?.onClick
                        ? (event) => handleClick(event, row)
                        : undefined
                    }
                    tabIndex={-1}
                    selected={isItemSelected}
                    style={isFunction(rowStyle) ? rowStyle(row) : rowStyle}
                    columns={columns}
                    selectable={!!selectRow}
                    isItemSelected={isItemSelected}
                    handleCheckBox={handleCheckBox(row)}
                  />
                );
              })}
              {!loading && !slicedRows?.length ? (
                <Row>
                  <TableCell
                    colSpan={
                      columns.length +
                      (selectionEnabled ? 1 : 0) +
                      (renderChildData ? 1 : 0)
                    }
                  >
                    <Box
                      p={1}
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      {emptyMessage || 'No data found'}
                    </Box>
                  </TableCell>
                </Row>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Spin>
      {pagination && (
        <Box
          mt={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="center">
            <Typography variant="body2" color="text.secondary" mx={1}>
              Show
            </Typography>
            <Select
              value={pagination?.limit ?? rowsPerPage}
              onChange={handleChangeRowsPerPage}
              size="small"
              sx={{
                'backgroundColor': 'background.lighter',
                'height': 32,
                'fontSize': pxToRem(12),
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
            >
              {(pagination?.rowsPerPageOptions || [5, 10, 20, 30, 40, 50]).map(
                (value) => (
                  <MenuItem value={value} key={value}>
                    {value} Rows
                  </MenuItem>
                ),
              )}
            </Select>
          </Box>
          <Pagination
            count={pageCount}
            shape="rounded"
            color="primary"
            page={pagination?.page ?? page}
            onChange={handleChangePage}
            {...paginationProps}
          />
        </Box>
      )}
    </>
  );
};

export default DataTable;
