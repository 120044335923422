import React from 'react';
import QueryEditor from '../../alarms/components/QueryEditor';
import QuerySourceConfig from '../../alarms/components/QuerySourceConfig';
import { resolveTemplateVariables } from '../../dashboards/utils';

const ExploreQuery = ({
  values,
  resolvedVariablesValues,
  defaultRegion,
  setFieldValue,
  workspace,
  runQuery,
  variableOptions,
  index,
  node,
  onChangeUnit,
}) => {
  return (
    <QueryEditor
      compact
      sx={{ p: 2 }}
      unresolvedValue={node}
      value={node}
      nodeConfig={'node_configs'}
      name={`node_configs.${index}`}
      setFieldValue={setFieldValue}
      workspace={workspace}
      onRemove={() => {
        const newConfigs = [...values.node_configs];
        newConfigs.splice(index, 1);
        setFieldValue('node_configs', newConfigs);
      }}
      resolvedVariablesValues={resolvedVariablesValues}
      onChange={() => {
        const config = resolveTemplateVariables(
          {
            ...values,
            node_configs: [values.node_configs[index]],
          },
          resolvedVariablesValues,
          defaultRegion,
        );
        return runQuery(config);
      }}
      displayResult={false}
      variableOptions={variableOptions}
      querySourceConfig={
        <QuerySourceConfig
          name={`node_configs.${index}`}
          variableOptions={variableOptions}
          value={node}
          showAlarmTimePicker={false}
          setFieldValue={setFieldValue}
          panelType={values.panelType}
        />
      }
      timeRange={values.timeRange}
      onChangeUnit={onChangeUnit}
    />
  );
};

export default ExploreQuery;
