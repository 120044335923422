import { useTheme } from '@mui/styles';
import Chart from '..';
import { seriesBarsPlugin, stack } from '../utils';
import moment from 'moment';
import {
  format,
  formatResultToString,
} from 'modules/dashboards/components/panels/utils';

const getTime = (unix, tz, format) =>
  tz === 'local'
    ? moment.utc(unix).local().format(format)
    : moment.utc(unix).format(format);

const BarChart = ({
  data,
  height,
  timeValuesTpl,
  series,
  horizontal,
  stacked,
  legend,
  tz,
  isTimeSeries,
  yFormat,
}) => {
  const theme = useTheme();

  let chartData, bands;
  if (!stacked) {
    chartData = data;
  } else {
    const stackData = stack(data, (i) => false);
    chartData = stackData.data;
    bands = stackData.bands;
  }

  const timeFormat = timeValuesTpl.replaceAll(/[\{\}]/g, '');

  const yFormatter = (value) =>
    formatResultToString(format({ value, ...yFormat }));

  const getAxisSize =
    ({ isVerticalAxis }) =>
    (_, values) => {
      if (!isVerticalAxis || !values) {
        return 35;
      }
      const maxLen = Math.max(...values.map((v) => `${v}`.length));
      const size = maxLen * 12;
      return size < 30 ? 30 : size;
    };

  return (
    <Chart
      data={chartData}
      bands={bands}
      tooltipData={data}
      height={height}
      chartSeries={series}
      cursor={{
        show: true,
        points: {
          size: 0,
        },
        focus: {
          prox: 1200,
        },
      }}
      showLegend={true}
      legendView={legend.view}
      legendAppendValues={legend.values.map(({ value }) => value)}
      scales={{
        y: {
          range: [0, null],
          ori: !horizontal ? 1 : 0,
        },
      }}
      axes={[
        {
          stroke: theme.palette.text.secondary,
          grid: {
            show: true,
            stroke: theme.palette.text.secondary,
            width: 0.3,
            dash: [3],
          },
          border: {
            show: true,
            stroke: theme.palette.text.secondary,
            width: 0.5,
          },
          values: (u, splits) => {
            if (!isTimeSeries) {
              return splits;
            }
            let noOfSplits;
            if (horizontal) {
              noOfSplits = Math.ceil(0.03125 * u.height - 2.71875);
            } else {
              noOfSplits = Math.ceil(0.0083 * u.width - 0.62);
            }
            const step = Math.ceil(splits.length / noOfSplits);

            let next = 0;
            return splits.map((s, idx) => {
              if (horizontal && idx < Math.ceil((5 * splits.length) / 100)) {
                next += 1;
                return null;
              }
              if (idx === next) {
                next += step;
                return getTime(s, tz, timeFormat);
              }
              return null;
            });
          },
          size: getAxisSize({ isVerticalAxis: horizontal }),
        },
        {
          side: !horizontal ? 3 : 2,
          stroke: theme.palette.text.secondary,
          grid: {
            show: true,
            stroke: theme.palette.text.secondary,
            width: 0.3,
            dash: [3],
          },
          border: {
            show: true,
            stroke: theme.palette.text.secondary,
            width: 0.5,
          },
          values: (u, splits) => {
            const formattedSplits = splits.map(yFormatter);
            if (!horizontal) {
              return formattedSplits;
            }
            const maxLen = Math.max(
              ...formattedSplits.map((v) => `${v}`.length),
            );

            const mod = Math.ceil((maxLen + 1) / 4);
            return formattedSplits.map((v, idx) =>
              idx === 0 || idx % mod === 0 ? v : null,
            );
          },
          size: getAxisSize({ isVerticalAxis: !horizontal }),
        },
      ]}
      xTooltipFormatter={(value) =>
        isTimeSeries ? getTime(value, tz, 'YYYY-MM-DD HH:mm:ss') : value
      }
      yValFormatter={yFormatter}
      appendPlugins={[
        seriesBarsPlugin({
          ori: horizontal ? 1 : 0,
          dir: horizontal ? -1 : 1,
          stacked,
          groupWidth: stacked ? 0.4 : 0.9,
        }),
      ]}
    />
  );
};

export default BarChart;
