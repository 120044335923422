import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';

const ColorPicker = ({ value, onChange, disabled, ...rest }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = ({ hex }) => {
    onChange && onChange(hex);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  return (
    <>
      <Box
        width={20}
        height={20}
        bgcolor={value}
        borderRadius={1}
        sx={!disabled && { cursor: 'pointer' }}
        onClick={handleClick}
        {...rest}
      />
      {!disabled && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <SketchPicker onChange={handleChange} color={value} />
        </Popover>
      )}
    </>
  );
};

export default ColorPicker;