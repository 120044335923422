import React from 'react';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useFormikContext } from 'formik';
import Typography from '@mui/material/Typography';

export const ORIENTATION_OPTIONS = {
  vertical: { value: 'vertical', label: 'Vertical' },
  horizontal: { value: 'horizontal', label: 'Horizontal' },
};

export const STACKING_OPTIONS = {
  stacked: { value: 'stacked', label: 'Stacked' },
  grouped: { value: 'grouped', label: 'Grouped' },
};

const ID = 'display';

const Display = ({ name }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems={'center'}>
        <Typography variant="subtitle1" color="text.secondary">
          Orientation:
        </Typography>
        <ToggleButtonGroup
          exclusive
          variant="outlined"
          size="small"
          value={values[name][ID].orientation}
          onChange={(e, v) => {
            setFieldValue(`${name}.${ID}.orientation`, v);
          }}
        >
          {Object.values(ORIENTATION_OPTIONS).map(({ label, value }, index) => {
            return (
              <ToggleButton disableRipple key={index} value={value}>
                {label}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Stack>
      <Stack direction="row" alignItems={'center'}>
        <Typography variant="subtitle1" color="text.secondary">
          Stacking:
        </Typography>
        <ToggleButtonGroup
          exclusive
          variant="outlined"
          size="small"
          value={values[name][ID].stacking}
          onChange={(e, v) => {
            setFieldValue(`${name}.${ID}.stacking`, v);
          }}
        >
          {Object.values(STACKING_OPTIONS).map(({ label, value }, index) => {
            return (
              <ToggleButton disableRipple key={index} value={value}>
                {label}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Stack>
    </Stack>
  );
};

export const CONFIG = {
  id: ID,
  name: 'Display',
  description: 'Customize display',
  Component: Display,
  default: {
    [ID]: {
      orientation: ORIENTATION_OPTIONS.vertical.value,
      stacking: STACKING_OPTIONS.stacked.value,
    },
  },
};

export default Display;
