import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { useMemo } from 'react';

const ListPanel = ({ result, height, runQuery, config, children }) => {
  const pagesCount = result?.total / result?.limit;

  // useMemo for smoother drag and drop
  const panel = useMemo(() => {
    return (
      <>
        <Box height={height - (pagesCount > 1 ? 20 : 0)}>{children}</Box>
        {pagesCount > 1 && (
          <Box height={20} display={'flex'} justifyContent={'end'} py={1}>
            <Pagination
              count={Math.ceil(pagesCount, 10)}
              shape="rounded"
              color="primary"
              page={result.page}
              size={'small'}
              onChange={(_, value) => {
                runQuery({
                  ...config,
                  node_configs: [
                    {
                      ...config.node_configs[0],
                      page: value,
                    },
                  ],
                });
              }}
            />
          </Box>
        )}
      </>
    );
  }, [height, pagesCount]);

  return panel;
};

export default ListPanel;
