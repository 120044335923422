import React from 'react';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useFormikContext, Field } from 'formik';
import Select from 'components/Form/Formik/FormikReactSelect';

export const DISPLAY_OPTIONS = {
  pie: { value: 'pie', label: 'Pie' },
  donut: { value: 'donut', label: 'Donut' },
};

const LABELS = {
  value: { value: 'value', label: 'Value' },
  percent: { value: 'percent', label: 'Percentage' },
  name: { value: 'name', label: 'Name' },
};

const ID = 'display';

const Display = ({ name }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <Stack spacing={1}>
      <ToggleButtonGroup
        exclusive
        variant="outlined"
        size="small"
        value={values[name][ID].type}
        onChange={(e, v) => {
          setFieldValue(`${name}.${ID}.type`, v);
        }}
      >
        {Object.values(DISPLAY_OPTIONS).map(({ label, value }, index) => {
          return (
            <ToggleButton disableRipple key={index} value={value}>
              {label}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      <Field
        fullwidth
        isMulti
        size="small"
        label={'Labels'}
        name={`${name}.${ID}.labels`}
        component={Select}
        transformValue
        options={Object.values(LABELS)}
      />
    </Stack>
  );
};

export const CONFIG = {
  id: ID,
  name: 'Display',
  description: 'Customize display',
  Component: Display,
  default: {
    [ID]: {
      type: DISPLAY_OPTIONS.pie.value,
      labels: [LABELS.name.value],
    },
  },
};

export default Display;
