import { CONFIG as alarmsListConfig } from './alarms-list';
import { CONFIG as issuesListConfig } from './issues-list';
import { CONFIG as timeseriesConfig } from './timeseries';
import { CONFIG as tableConfig } from './table';
import { CONFIG as statsConfig } from './stats';
import { CONFIG as logsListConfig } from './logs-list';
import { CONFIG as spansListConfig } from './spans-list';
import { CONFIG as pieChartConfig } from './pie-chart';
import { CONFIG as barChartConfig } from './bar-chart';

export const allPanelConfigs = [
  timeseriesConfig,
  statsConfig,
  tableConfig,
  pieChartConfig,
  barChartConfig,
  logsListConfig,
  spansListConfig,
  alarmsListConfig,
  issuesListConfig,
];

export const getConfig = (panelType) =>
  allPanelConfigs.find(({ id }) => panelType === id);
