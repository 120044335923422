import { Field } from 'formik';
import Select from 'components/Form/Formik/FormikReactSelect';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Formatter, { CONFIG as formatterConfig } from './Formatter';

const X_AXIS_DATE_STYLES = [
  { label: 'Auto', value: 'auto' },
  { label: '19 January, 2022', value: '{DD} {MMM} {YYYY}' },
  { label: 'January 19, 2022', value: '{MMM} {DD} {YYYY}' },
  { label: '19.1.2022 (day,month,year)', value: '{DD}.{M}.{YYYY}' },
  { label: '1.19.2022 (month,day,year)', value: '{M}.{DD}.{YYYY}' },
  { label: '2022.1.19 (year,month,day)', value: '{YYYY}.{M}.{DD}' },
];

const ID = 'axis';

const Axes = ({ name, values, hideYRight }) => {
  return (
    <Stack spacing={1}>
      <>
        <Typography variant="subtitle1" color="text.secondary">
          X-Axis
        </Typography>
        <Stack spacing={1}>
          <Field
            fullWidth
            transformValue
            margin="none"
            name={`${name ? `${name}.` : ''}axis.x.date_style`}
            label="Date Style"
            component={Select}
            options={X_AXIS_DATE_STYLES}
          />
        </Stack>
      </>
      <Divider />
      {[
        { value: 'y', tabName: 'Y-Axis' },
        !hideYRight && { value: 'y1', tabName: 'Y-Axis (right)' },
      ]
        .filter(Boolean)
        .map(({ value, tabName }) => {
          return (
            <>
              <Typography variant="subtitle1" color="text.secondary">
                {tabName}
              </Typography>
              <Formatter
                name={`${name ? `${name}.` : ''}axis.${value}`}
                values={values}
              />
              <Divider />
            </>
          );
        })}
    </Stack>
  );
};

export const CONFIG = {
  id: ID,
  name: 'Axes',
  description: 'Customize axes',
  default: {
    [ID]: {
      x: {
        date_style: 'auto',
      },
      y: {
        ...formatterConfig.default,
      },
    },
  },
  Component: Axes,
};

export default Axes;
