import { useFormikContext } from 'formik';
import TextField from 'components/Form/TextInput';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';

const NonAggLimit = ({ name }) => {
  const { values, setFieldValue } = useFormikContext();

  const fieldName = `${name}.limit`;
  const limit = get(values, fieldName);

  return (
    <TextField
      size="small"
      label={<Typography color="text.primary">Limit</Typography>}
      margin="none"
      name={fieldName}
      type="number"
      value={limit}
      onChange={(e) => {
        setFieldValue(fieldName, e.target.value);
      }}
    />
  );
};

export default NonAggLimit;
