import React from 'react';
import Stack from '@mui/material/Stack';
import Button from 'components/Button';
import findLast from 'lodash/findLast';
import { getNextAlphabet } from '../utils';
import { DEFAULT_PANEL_CONFIG } from 'modules/dashboards/utils';
import { getConfig } from 'modules/dashboards/components/panels';

const NodeActionBar = ({ values, push, onRunQuery, nodeConfig }) => {
  const hasOneNode = values[nodeConfig].length === 1;
  const lastQueryNode = findLast(values[nodeConfig], (i) => i.type === 'query');
  const panelInternalConfig = getConfig(values.panelType);
  const multiNodes = panelInternalConfig?.multiNodes ?? true;

  return (
    <Stack direction="row" spacing={1}>
      {multiNodes && (
        <Button
          color="neutral"
          onClick={() =>
            push({
              ...DEFAULT_PANEL_CONFIG,
              interval: lastQueryNode?.interval,
              datasourceId: lastQueryNode?.datasourceId,
              dataset: lastQueryNode?.dataset,
              nodeId: getNextAlphabet(
                values[nodeConfig][values[nodeConfig].length - 1]?.nodeId,
              ),
            })
          }
        >
          Add Query
        </Button>
      )}
      {multiNodes && (
        <Button
          color="neutral"
          onClick={() =>
            push({
              type: 'expression',
              hidden: false,
              nodeId: getNextAlphabet(
                values[nodeConfig][values[nodeConfig].length - 1]?.nodeId,
              ),
            })
          }
        >
          Add Expression
        </Button>
      )}
      <Button color="secondary" onClick={() => onRunQuery(values)}>
        Run Quer{hasOneNode ? 'y' : 'ies'}
      </Button>
    </Stack>
  );
};

export default NodeActionBar;
