import React from 'react';
import { useSubscription } from '@apollo/client';
import { GET_ALARMS } from 'graphql/alarms/queries';
import { useSelector } from 'store';
import useAlarmData from 'modules/alarms/useAlarmData';
import { ActiveAlarmsView } from 'modules/dashboards/components/panels/alarms-list/ActiveAlarmsView';

const ActiveAlarms = ({ title, icon, isEditable, onDelete, serviceName }) => {
  const { workspace } = useSelector((s) => s.workspace);
  const { data, loading, error } = useSubscription(GET_ALARMS(), {
    variables: {
      query: {
        workspace_id: { _eq: workspace.id },
        alarm_states: {
          labels: serviceName
            ? { _contains: [['serviceName', serviceName]] }
            : undefined,
        },
      },
      orderBy: { name: 'asc' },
    },
  });
  const { alarms, firingCount } = useAlarmData(data?.alarm_rules, {
    limit: 5,
    page: 1,
    state: 'Alerting',
  });

  return (
    <ActiveAlarmsView
      isEditable={isEditable}
      onDelete={onDelete}
      title={title}
      loading={loading}
      error={error}
      alarms={alarms}
      firingCount={firingCount}
    />
  );
};

export default ActiveAlarms;
