import Box from '@mui/material/Box';
import Select from 'components/Form/Select';
import { DATA_SET } from 'constants/data-sources';
import { defaultAggregate } from './Aggregation';
import { QUERY_TYPE } from 'modules/alarms/utils';

const DataSet = ({ name, queryOptions, updateQueryOptions }) => {
  return (
    <Box>
      <Select
        fullWidth
        transformValue
        size="small"
        name="dataset"
        placeholder="Data Set"
        margin="none"
        options={[
          { label: 'Metrics', value: DATA_SET.METRICS },
          { label: 'Traces', value: DATA_SET.TRACES },
          { label: 'Logs', value: DATA_SET.LOGS },
        ]}
        value={queryOptions.dataset}
        onChange={(val) => {
          updateQueryOptions(name, {
            ...queryOptions,
            dataset: val,
            filters: [],
            aggregation: defaultAggregate,
            groupBy: [],
            columns: [],
            orderBy: [],
            query_type: QUERY_TYPE.AGG,
          });
        }}
      />
    </Box>
  );
};

export default DataSet;
