import { Field } from 'formik';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Formatter from './Formatter';

const StatConfig = ({ values, setFieldValue }) => {
  const STATVIEW = [
    { label: 'Grid', value: 'grid' },
    { label: 'Table', value: 'table' },
  ];

  return (
    <Stack spacing={1}>
      <>
        <Field name={'statConfig.statView'}>
          {({ field }) => {
            return (
              <Stack direction="row" alignItems={'center'} spacing={1}>
                <Typography gutterBottom variant="body1" color="text.secondary">
                  Stat Mode
                </Typography>
                <ToggleButtonGroup
                  value={field.value || 'list'}
                  onChange={(e, val) => {
                    setFieldValue(field.name, val);
                  }}
                  size="small"
                  exclusive
                >
                  {STATVIEW.map((view) => (
                    <ToggleButton value={view.value}>{view.label}</ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Stack>
            );
          }}
        </Field>
        <Divider />
        <Formatter name={'statConfig'} values={values} />
      </>
    </Stack>
  );
};

export const CONFIG = {
  id: 'statConfig',
  name: 'Stat Config',
  description: 'Configure stats',
  Component: StatConfig,
};

export default StatConfig;
