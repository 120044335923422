import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import KeysField from './KeysField';

const Selects = ({
  name,
  queryOptions,
  updateQueryOptions,
  variableOptions,
  from,
  to,
}) => {
  return (
    <>
      <Typography gutterBottom variant="body2">
        Select Columns
      </Typography>
      <KeysField
        isMulti
        isClearable
        fullWidth
        limitTags={6}
        dataset={queryOptions.dataset}
        placeholder="Select field"
        name={`${name}.columns`}
        onChange={(name, values) => {
          updateQueryOptions(
            name,
            values.map((v) => {
              return v.type ? { field: v.field, type: v.type } : v.value;
            }),
          );
        }}
        value={queryOptions.columns?.map((col) => {
          return col.field ? col : { label: col, value: col };
        })}
        from={from}
        to={to}
        variableOptions={variableOptions}
      />
    </>
  );
};

export default Selects;
