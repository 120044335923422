import { gql } from '@apollo/client';
import { issueFragments } from './fragments';

export const GET_ISSUES = gql`
  query getIssues($query: issues_bool_exp, $offset: Int!, $limit: Int!) {
    rows: issues(
      where: $query
      offset: $offset
      limit: $limit
      order_by: { last_occurrence_at: desc }
    ) {
      ...Issue
    }
    aggregate: issues_aggregate(where: $query) {
      aggregate {
        count
      }
    }
  }
  ${issueFragments}
`;

export const GET_SERVICE_NAMES = gql`
  query getServiceNames($query: issues_bool_exp) {
    rows: issues(where: $query, distinct_on: service_name) {
      service_name
    }
  }
`;

export const GET_RECENT_ISSUES = gql`
  query getIssues(
    $query: issues_bool_exp!
    $orderBy: [issues_order_by!]
    $limit: Int
    $offset: Int
  ) {
    rows: issues(
      where: $query
      offset: $offset
      limit: $limit
      order_by: $orderBy
    ) {
      ...Issue
    }
    aggregate: issues_aggregate(where: $query) {
      aggregate {
        count
      }
    }
  }

  ${issueFragments}
`;

export const SUB_GET_ISSUE = gql`
  subscription getIssue($id: uuid!) {
    row: issues_by_pk(id: $id) {
      ...Issue
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
  }
  ${issueFragments}
`;

export const UPDATE_ISSUES = gql`
  mutation updateIssues($ids: [uuid!]!, $update: issues_set_input!) {
    update_issues(where: { id: { _in: $ids } }, _set: $update) {
      returning {
        ...Issue
      }
    }
  }
  ${issueFragments}
`;

export const GET_ISSUE_ANNOTATIONS = gql`
  query getIssueAnnotations($query: issues_bool_exp) {
    rows: issues(where: $query, limit: 100) {
      id
      message
      error_type
      last_occurrence_at
      count
    }
  }
  ${issueFragments}
`;

export const GET_ISSUE_COLUMN_VALUES = (column) => gql`
  query getIssueColumnValues($query: issues_bool_exp!, $column: [issues_select_column!]) {
    rows: issues(where: $query, distinct_on: $column) {
      ${column || 'id'}
    }
  }
`;