export const DATA_SOURCES = {
  CLOUDWTACH: 'cloudwatch',
  KLOUDMATE: 'kloudmate',
};

export const DATA_SET = {
  METRICS: 'metrics',
  TRACES: 'traces',
  LOGS: 'logs',
  ALARMS: 'alarms',
  ISSUES: 'issues',
};
