import isNil from 'lodash/isNil';
import numeral from 'numeral';
import prettyMs from 'pretty-ms';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const trimTrailingZeroesAfterDecimal = (value) => {
  return value
    .toString()
    .replace(/(?<=\d+\.\d*)(0+)(?!\.)(?!\d)/g, '')
    .replace(/(?<=\d+)(\.)(?=$|\D)/g, '');
};

export const getValue = (v, defaultValue) => {
  return isNil(v) || v === '' ? defaultValue : v;
};

export const formatResultToString = (result) => {
  return `${result.prefix}${result.value}${result.suffix}`;
};

export const format = ({
  value,
  format,
  multiplyBy: _multiplyBy,
  decimals: _decimals,
  prefix: _prefix,
  suffix: _suffix,
}) => {
  const multiplyBy = getValue(_multiplyBy, 1);
  const dec = getValue(_decimals, 3);
  let decimals = dec;
  if (dec > 10) {
    decimals = 10;
  }
  if (dec < 0) {
    decimals = 0;
  }
  const prefix = getValue(_prefix, '');
  const suffix = getValue(_suffix, '');

  if (isNil(value)) {
    return {
      value,
      suffix,
      prefix,
    };
  }

  const formatDecimals = Array(decimals).fill(0).join('');
  let formatString = decimals === 0 ? '0' : `0[.]${formatDecimals}`;
  switch (format) {
    case 'bytes_iec': {
      formatString += 'ib';
      break;
    }
    case 'bytes': {
      formatString += 'b';
      break;
    }
    case 'percentage': {
      formatString += '%';
      break;
    }
    case 'duration': {
      const val = value * multiplyBy;
      if (!isFinite(val) || val === null) return '-';
      return {
        value: trimTrailingZeroesAfterDecimal(
          prettyMs(val, {
            millisecondsDecimalDigits: decimals,
            secondsDecimalDigits: decimals,
          }),
        ),
        prefix,
        suffix,
      };
    }
    case 'duration_nano': {
      const val = value * multiplyBy;
      if (!isFinite(val) || val === null) return '-';
      return {
        value: trimTrailingZeroesAfterDecimal(
          prettyMs(val / 1000000, {
            millisecondsDecimalDigits: decimals,
            secondsDecimalDigits: decimals,
          }),
        ),
        prefix,
        suffix,
      };
    }
    case 'number': {
      formatString += 'a';
      break;
    }
    case 'unformatted':
    default:
      break;
  }



  const formattedValue = trimTrailingZeroesAfterDecimal(
    numeral(value * multiplyBy).format(formatString),
  );

  return {
    value: formattedValue,
    suffix,
    prefix,
  };
};

export const noDataMessage = (
  <Grid
    container
    width="100%"
    height="100%"
    justifyContent="center"
    alignItems="center"
  >
    <Typography variant="h5" color="text.secondary">
      No Data
    </Typography>
  </Grid>
);

export const getStatPanelLabel = (row) =>
  row.nodeId + '-' + (row.legend || Object.values(row.labels || {}).join(', '));

export const darkModeColors = [
  '#36A2EB',
  '#FFCE56',
  '#4BC0C0',
  '#9966FF',
  '#FF9F40',
  '#66FF66',
  '#FF66B2',
  '#6699FF',
  '#FF6384',
  '#FFCC66',
  '#66FFFF',
  '#999966',
  '#FF9999',
  '#99FF99',
  '#66CCCC',
  '#CC99FF',
  '#FF99CC',
  '#33FF66',
  '#3399FF',
  '#CCFF66',
  '#FF6699',
  '#66FF99',
  '#33CCCC',
  '#CC6666',
  '#FF66FF',
  '#66CC66',
  '#FFCC33',
  '#6699CC',
  '#CCFF99',
  '#9933FF',
];

export const lightModeColors = [
  '#36A2EB',
  '#FFCE56',
  '#4BC0C0',
  '#9966FF',
  '#FF9F40',
  '#70D6FF',
  '#FFADAD',
  '#9BDEAC',
  '#FF6384',
  '#FFD670',
  '#FFC6FF',
  '#FF9E00',
  '#BDB2FF',
  '#CAFFBF',
  '#FFD6A5',
  '#A0C4FF',
  '#FDFFB6',
  '#FFB4A2',
  '#84A59D',
  '#B56576',
  '#E5989B',
  '#6D6875',
  '#FFCAD4',
  '#4D908E',
  '#9A8C98',
  '#99D98C',
  '#E9C46A',
  '#F4A261',
  '#2A9D8F',
  '#F5CB5C',
];