import React from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { format, getStatPanelLabel } from '../utils';
import DataTable from 'components/DataTable';
import { noDataMessage } from '../utils';
import Tooltip from '@mui/material/Tooltip';
import max from 'lodash/max';
import min from 'lodash/min';
import mean from 'lodash/mean';
import first from 'lodash/first';
import last from 'lodash/last';
import sum from 'lodash/sum';
import flatMap from 'lodash/flatMap';
import { CONFIG as panelConfig } from '../../PanelSettings/Panel';
import { CONFIG as timeConfig } from '../../PanelSettings/TimeRangeOptions';
import { CONFIG as calcConfig } from '../../PanelSettings/StatCalculation';
import { CONFIG as statConfig } from '../../PanelSettings/StatConfig';
import { CONFIG as thresholdConfig } from '../../PanelSettings/Threshold';
import { CONFIG as formatterConfig } from '../../PanelSettings/Formatter';
import {
  evaluateCondition,
  CONFIG as valuesMappingConfig,
} from '../../PanelSettings/stats/ValueMappings';
import { CONFIG as renameConfig } from '../../PanelSettings/stats/Rename';
import { QUERY_TYPE } from 'modules/alarms/utils';
import { DATA_SET } from 'constants/data-sources';
import { defaultAggregate } from 'modules/alarms/components/kloudmate/Aggregation';
import isNil from 'lodash/isNil';

export const CONFIG = {
  id: 'STATS',
  settings: {
    general: [
      panelConfig,
      calcConfig,
      { ...renameConfig, props: { name: 'statConfig' } },
      statConfig,
      {
        ...thresholdConfig,
        id: 'statThreshold',
        props: { config: 'statConfig' },
      },
      { ...valuesMappingConfig, props: { name: 'statConfig' } },
      timeConfig,
    ],
    overrides: [
      { ...thresholdConfig, id: 'thresholds' },
      { ...formatterConfig, id: 'units', name: 'Units' },
      { ...calcConfig, id: 'calculations' },
    ],
  },
  name: 'Stats',
  description: 'Stats',
  multiNodes: true,
  defaultNodeConfig: {
    query_type: QUERY_TYPE.AGG,
    dataset: DATA_SET.METRICS,
    aggregation: defaultAggregate,
    limit: null,
    responseType: 'series',
  },
  default: {
    ...valuesMappingConfig.default,
    ...renameConfig.default,
  },
  showTableView: true,
  configurableSource: true,
};

export const onChangeUniStat = ({ format, values, setFieldValue, nodeId }) => {
  const isFirstNode = (values.node_configs || [])[0]?.nodeId === nodeId;

  isFirstNode &&
    setFieldValue('statConfig', { ...values.statConfig, ...format });
};

const StatPanel = ({ queryResult, values, height }) => {
  const formatValue = (config, value) => {
    return format({
      ...config,
      value,
    });
  };

  const getCalculationType = (matchedOverride, values) => {
    return (
      matchedOverride?.properties?.find((property) => property.calculation)
        ?.calculation.value ??
      values?.statConfig?.calculation?.value ??
      'last'
    );
  };

  const postCalculateValues = (valuesArray, calculationType) => {
    switch (calculationType) {
      case 'max':
        return max(valuesArray);
      case 'min':
        return min(valuesArray);
      case 'mean':
        return mean(valuesArray).toFixed(2);
      case 'first':
        return first(valuesArray);
      case 'total':
        return sum(valuesArray);
      case 'count':
        return valuesArray.length;
      case 'last':
        return last(valuesArray);
      default:
        return last(valuesArray);
    }
  };

  const dataArray = [];
  const minResult = min(flatMap(queryResult, 'values'));
  const maxResult = max(flatMap(queryResult, 'values'));

  const renameMapping = (values.statConfig?.rename || []).reduce(
    (acc, curr) => {
      acc[curr.rename] = curr.to;
      return acc;
    },
    {},
  );

  queryResult?.forEach((result) => {
    const matchedOverride = values?.overrides?.find(
      (override) =>
        (override.by === 'query' && override.value === result.nodeId) ||
        (override.by === 'name' && override.value === result.name) ||
        (override.by === 'regex' && result.name.includes(override.value)),
    );

    const labelKey = getStatPanelLabel(result);

    const valuesArray = result?.values || [];

    const calculationType = getCalculationType(matchedOverride, values);
    let cardValue = valuesArray.length
      ? postCalculateValues(valuesArray, calculationType)
      : undefined;

    const minValue = min(valuesArray);
    const maxValue = max(valuesArray);

    const thresholdMode =
      matchedOverride?.properties?.find((property) => property.thresholdMode) ||
      values?.statConfig?.thresholdMode;
    const isSeriesType = result.type === 'series';
    const percentageValue =
      minResult !== maxResult
        ? matchedOverride?.properties?.some(
            (property) => property.thresholdMode === 'percentage',
          )
          ? minValue !== maxValue
            ? ((cardValue - minValue) / (maxValue - minValue)) * 100
            : minValue
          : ((cardValue - minResult) / (maxResult - minResult)) * 100
        : minResult;

    let color = matchedOverride?.properties?.some(
      (property) => property.type === 'thresholds',
    )
      ? matchedOverride?.properties?.find((property) => property?.baseColor)
          ?.baseColor
      : values?.statConfig?.baseColor;

    const thresholds = (
      matchedOverride?.properties?.find((property) =>
        Array.isArray(property.thresholds),
      )?.thresholds ||
      values?.statConfig?.thresholds ||
      []
    ).slice();

    thresholds.sort((a, b) => a.value - b.value).reverse();

    for (const threshold of thresholds || []) {
      if (
        thresholdMode === 'percentage' &&
        isSeriesType &&
        percentageValue > threshold.value
      ) {
        color = threshold.color;
        break;
      } else if (cardValue > threshold.value) {
        color = threshold.color;
        break;
      }
    }

    const config =
      matchedOverride?.properties?.find(
        (property) => property?.format !== undefined,
      ) || values?.statConfig;

    let mappedValue;
    const mapping = (values.statConfig?.value_mappings || []).find((mapping) =>
      evaluateCondition(mapping, cardValue),
    );
    if (mapping) {
      mappedValue = mapping.replace_with;
      color = mapping.color;
    }

    dataArray.push({
      label: renameMapping[labelKey] || labelKey,
      value: !mappedValue
        ? formatValue(config, cardValue)
        : { value: mappedValue, suffix: '', prefix: '' },
      color,
    });
  });

  const sortData = (sortField, sortOrder) => {
    dataArray.sort((a, b) => {
      const valueA = a[sortField];
      const valueB = b[sortField];
      const parsedValueA = parseFloat(valueA);
      const parsedValueB = parseFloat(valueB);

      if (!isNaN(parsedValueA) && !isNaN(parsedValueB)) {
        return sortOrder === 'asc'
          ? parsedValueA - parsedValueB
          : parsedValueB - parsedValueA;
      }

      return sortOrder === 'asc'
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
  };

  const onTableChange = (e, props) => {
    if (e === 'sort') {
      const { sortField, sortOrder } = props;
      sortData(sortField, sortOrder);
    }
  };

  const columns = [
    {
      dataField: 'label',
      text: 'Labels',
      sortable: true,
    },
    {
      dataField: 'value',
      text: 'Value',
      sortable: true,
      formatter: (cell, row) =>
        row.value.value !== undefined
          ? `${row?.value?.prefix} ${row?.value?.value} ${row?.value?.suffix}`
          : 'No Data',
    },
  ];

  return queryResult && queryResult.length ? (
    values?.statConfig?.statView === 'table' ? (
      <DataTable
        columns={columns}
        data={dataArray}
        tableContainerStyle={{ maxHeight: height }}
        stickyHeader={true}
        onTableChange={onTableChange}
      />
    ) : (
      <Grid container height={height} overflow="auto">
        {dataArray?.map((row, index) => {
          const totalLength = ['prefix', 'value', 'suffix'].reduce(
            (acc, key) => acc + (row.value[key]?.length || 0),
            0,
          );

          const calculateFontSize = (totalLength, baseFontSize) => {
            return totalLength <= 9
              ? baseFontSize
              : baseFontSize - Math.floor((totalLength - 8) / 2);
          };

          return (
            <Grid
              item
              key={index}
              padding={1}
              sx={{
                flex: 1,
              }}
            >
              <Tooltip title={queryResult.length > 1 && row.label}>
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    border: 1,
                    borderColor: 'divider',
                    borderRadius: '4px',
                    width: '100%',
                    height: '100%',
                    minWidth: '100px',
                  }}
                >
                  <Box
                    sx={{
                      containerType: 'size',
                    }}
                  >
                    <Typography
                      sx={{
                        mt: 0,
                        mb: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '5cqmax',
                      }}
                      paddingX={1}
                    >
                      {queryResult.length > 1 && row.label}
                    </Typography>
                  </Box>
                  <Box
                    flex={1}
                    display="flex"
                    padding={1}
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      containerType: 'size',
                    }}
                  >
                    {['prefix', 'value', 'suffix'].map((key, idx) => (
                      <Typography
                        key={idx}
                        fontWeight={700}
                        sx={{
                          fontSize: `${calculateFontSize(
                            totalLength,
                            idx === 1 ? 15 : 10,
                          )}cqmax`,
                          color: `${row.color}`,
                        }}
                      >
                        {key === 'value' && isNil(row.value[key])
                          ? 'No Data'
                          : !isNil(row.value[key])
                          ? `${row.value[key]}`
                          : ''}
                      </Typography>
                    ))}
                  </Box>
                </Card>
              </Tooltip>
            </Grid>
          );
        })}
      </Grid>
    )
  ) : (
    <Box height={height}>{noDataMessage}</Box>
  );
};

export default StatPanel;
