import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import KeysField from './KeysField';

const GroupBy = ({
  name,
  queryOptions,
  updateQueryOptions,
  variableOptions,
  from,
  to,
  loading,
}) => {
  return (
    <Box>
      <Typography gutterBottom variant="body2">
        Group By
      </Typography>
      <KeysField
        isMulti
        isClearable
        fullWidth
        limitTags={2}
        dataset={queryOptions.dataset}
        placeholder="Select field"
        name={`${name}.groupBy`}
        onChange={updateQueryOptions}
        value={queryOptions.groupBy}
        aggregation={queryOptions.aggregation}
        from={from}
        to={to}
        variableOptions={variableOptions}
      />
    </Box>
  );
};

export default GroupBy;
