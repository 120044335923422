import { legendValues } from './Legend';

const StatCalculation = ({ name }) => {
  return legendValues({
    title: 'Choose a Calculation',
    name: name || 'statConfig.calculation',
  });
};

export const CONFIG = {
  id: 'statCalculation',
  name: 'Calculations',
  description: 'Configure stats',
  Component: StatCalculation,
};

export default StatCalculation;
