import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import AggQueryBuilder from './AggQueryBuilder';
import NonAggQueryBuilder from './NonAggQueryBuilder';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import PromQLQuery from './PromQLQuery';
import { DATA_SET } from 'constants/data-sources';
import { QUERY_TYPE } from 'modules/alarms/utils';

const KloudMateQueryForm = (props) => {
  const { name, values } = props;
  const { setFieldValue } = useFormikContext();

  const onTabChange = (_, val) => {
    switch (val) {
      case QUERY_TYPE.AGG: {
        setFieldValue(`${name}`, {
          ...values,
          query_type: val,
          responseType: 'series',
          limit: null,
        });
        break;
      }
      case QUERY_TYPE.PROMQL: {
        setFieldValue(`${name}`, {
          ...values,
          query_type: val,
          responseType: 'series',
          limit: null,
        });
        break;
      }
      default:
        break;
    }
  };

  const isMetricDS = values.dataset === DATA_SET.METRICS;
  const showTabs = isMetricDS;

  const tabs = [
    {
      id: QUERY_TYPE.AGG,
      name: isMetricDS ? 'Query Builder' : 'Aggregation',
      props,
    },
    isMetricDS
      ? {
          id: QUERY_TYPE.PROMQL,
          name: 'PromQL',
          props: { name },
        }
      : {
          id: QUERY_TYPE.NON_AGG,
          name: 'Non-Aggregation',
          props,
        },
  ];

  return (
    <TabContext value={values.query_type || QUERY_TYPE.AGG}>
      {showTabs && (
        <TabList onChange={onTabChange}>
          {tabs.map(({ name, id }) => (
            <Tab sx={{ maxHeight: 10 }} key={id} value={id} label={name} />
          ))}
        </TabList>
      )}
      {tabs.map(({ props, id }) => (
        <TabPanel key={id} value={id} sx={{ px: 0, pt: 1.5, pb: 0 }}>
          {id === QUERY_TYPE.AGG && <AggQueryBuilder {...props} />}
          {id === QUERY_TYPE.NON_AGG && <NonAggQueryBuilder {...props} />}
          {id === QUERY_TYPE.PROMQL && <PromQLQuery {...props} />}
        </TabPanel>
      ))}
    </TabContext>
  );
};

export default KloudMateQueryForm;
