import React from 'react';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import Stack from '@mui/material/Stack';
import DashboardCard from 'components/DashboardCard';
import { ReactComponent as AlarmIcon } from 'assets/icons/alarm.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from 'components/IconButton';

export const ActiveAlarmsView = ({
  title,
  isEditable,
  onDelete,
  loading,
  error,
  alarms,
  firingCount,
}) => (
  <DashboardCard
    keyField="id"
    loading={loading}
    error={error?.message}
    rows={alarms}
    title={
      title && (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="subtitle1">{title}</Typography>
          {isEditable && (
            <IconButton
              size="small"
              title="Delete Widget"
              icon={<DeleteIcon />}
              onClick={onDelete}
            />
          )}
        </Stack>
      )
    }
    getPrimaryText={(item) => item.name}
    getSecondaryText={(item) => item.description}
    getLink={(item) => `/${item.workspace_id}/alarms/${item.id}`}
    Icon={AlarmIcon}
    iconColor="error"
    count={firingCount}
    renderActions={(item) => (
      <Typography color="text.secondary" variant="body2">
        {moment(item.errorStateSince).fromNow(true)}
      </Typography>
    )}
    isEditable={isEditable}
  />
);

export default ActiveAlarmsView;
