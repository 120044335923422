import React, { useMemo } from 'react';
import moment from 'moment';
import { useFormikContext } from 'formik';
import Grid from '@mui/material/Grid';
import Filters from './Filters';
import Selects from './Selects';
import SortBy from './SortBy';
import NonAggLimit from './NonAggLimit';
import { DATA_SET } from 'constants/data-sources';

const NonAggQueryBuilder = ({
  name,
  values,
  variableOptions,
  from,
  to,
  onChangeUnit,
  resolvedVariablesValues,
}) => {
  const { setFieldValue } = useFormikContext();
  const isOTelDataset = [
    DATA_SET.LOGS,
    DATA_SET.TRACES,
    DATA_SET.METRICS,
  ].includes(values.dataset);

  const allowOrderBy = values.dataset !== DATA_SET.ALARMS; // for alarms list panel, we allow one filter - "serviceName". serviceName might be available in  alarm_States.labels. It is not possible to orderBy "serviceName" because of the way it is stored (jsonb array of arrays)

  return (
    <Grid container spacing={1} sx={{ mb: 1 }}>
      {isOTelDataset && (
        <Grid item xs={8}>
          <Selects
            queryOptions={values}
            updateQueryOptions={setFieldValue}
            variableOptions={variableOptions}
            name={name}
            from={from}
            to={to}
            onChangeUnit={onChangeUnit}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Filters
          queryOptions={values}
          onChange={setFieldValue}
          variableOptions={variableOptions}
          resolvedVariablesValues={resolvedVariablesValues}
          name={name}
          from={from}
          to={to}
          showHint
        />
      </Grid>
      {allowOrderBy && (
        <Grid item xs={8}>
          <SortBy
            name={name}
            queryOptions={values}
            variableOptions={variableOptions}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <NonAggLimit name={name} />
      </Grid>
    </Grid>
  );
};

export default NonAggQueryBuilder;
