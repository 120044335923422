import React, { useMemo, useCallback, useEffect, useState } from 'react';
import randomcolor from 'randomcolor';
import sortBy from 'lodash/sortBy';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/styles';
import moment from 'moment';
import {
  calcVertAxisWidth,
  useTZDate,
  defaultColors,
  formatTimeLabel,
} from 'components/UPlot/utils';
import { parseValue } from 'components/DateRangePickerNew';
import uPlot from 'uplot';
import Chart from 'components/UPlot';
import { TOOLTIP_MODES } from '../../PanelSettings/Tooltip';
import { AXIS_PLACEMENT } from '../../PanelSettings/Overrides/AxisPlacement';
import { OVERRIDE_FIELDS_BY } from '../../PanelSettings/Overrides';
import useDefaultAnnotations from './annotations/useDefaultAnnotations';
import DefaultAnnotation from './annotations';
import { useFormikContext } from 'formik';
import { format, formatResultToString, noDataMessage } from '../utils';
import { CONFIG as panelConfig } from '../../PanelSettings/Panel';
import { CONFIG as timeConfig } from '../../PanelSettings/TimeRangeOptions';
import { CONFIG as legendConfig } from '../../PanelSettings/Legend';
import { CONFIG as tooltipconfig } from '../../PanelSettings/Tooltip';
import { CONFIG as thresholdConfig } from '../../PanelSettings/Threshold';
import { CONFIG as axesConfig } from '../../PanelSettings/Axes';
import { CONFIG as axisPlacementConfig } from '../../PanelSettings/Overrides/AxisPlacement';
import { QUERY_TYPE } from 'modules/alarms/utils';
import { defaultAggregate } from 'modules/alarms/components/kloudmate/Aggregation';
import { DATA_SET } from 'constants/data-sources';

export const CONFIG = {
  id: 'TimeSeries',
  settings: {
    general: [
      panelConfig,
      axesConfig,
      { ...legendConfig, props: { name: 'timeseries' } },
      tooltipconfig,
      {
        ...thresholdConfig,
        props: { config: 'timeseries.threshold' },
      },
      timeConfig,
    ],
    overrides: [axisPlacementConfig],
  },
  name: 'TimeSeries',
  description: 'TimeSeries',
  multiNodes: true,
  defaultNodeConfig: {
    query_type: QUERY_TYPE.AGG,
    dataset: DATA_SET.METRICS,
    aggregation: defaultAggregate,
    limit: null,
    responseType: 'series',
  },
  showTableView: true,
  configurableSource: true,
};

export const onChangeUnitGraph = ({
  nodeId,
  format,
  data,
  values,
  setFieldValue,
}) => {
  const hash = data.reduce(
    (acc, { name, nodeId }) => ({ ...acc, [name]: nodeId }),
    {},
  );
  const nodeIds = (values.node_configs || []).map((d) => d.nodeId);
  const rightAxisNodeIds = (values.overrides || [])
    .filter((o) =>
      o.properties.find(
        (p) =>
          p.type === axisPlacementConfig.id &&
          p.value === AXIS_PLACEMENT.right.value,
      ),
    )
    .flatMap((override) => {
      return getOverrideFields({
        override,
        data,
        labels: data.map((d) => d.name),
      }).map((name) => hash[name]);
    });

  const firstLeftNodeId = nodeIds.find(
    (nodeId) => !rightAxisNodeIds.includes(nodeId),
  );

  if (rightAxisNodeIds[0] === nodeId) {
    setFieldValue(`axis.y1`, format);
  } else if (firstLeftNodeId === nodeId) {
    setFieldValue(`axis.y`, format);
  }
};

const getOverrideFields = ({ override, labels, data }) => {
  const { by, value, properties } = override;

  if (!by || !value || !properties || !properties.length) {
    return [];
  }

  let fieldsToOverride = [];
  switch (by) {
    case OVERRIDE_FIELDS_BY.name.value: {
      fieldsToOverride = labels.filter((label) => value === label);
      break;
    }
    case OVERRIDE_FIELDS_BY.regex.value: {
      fieldsToOverride = labels.filter((label) => {
        try {
          return new RegExp(value, 'g').test(label);
        } catch (err) {
          return false;
        }
      });
      break;
    }
    case OVERRIDE_FIELDS_BY.query.value: {
      fieldsToOverride = data
        .filter(({ nodeId }) => value === nodeId)
        .map((frame) => frame.name);
      break;
    }
    default:
      break;
  }

  return fieldsToOverride;
};

export const transformFramesToTable = (frames) => {
  if (!frames)
    return {
      graphData: [],
      labels: [],
    };

  const dataMap = {};
  const labels = {};

  frames.forEach((frame, index) => {
    const { name, timestamps, values } = frame;
    timestamps.forEach((ts, idx) => {
      const value = values[idx];
      if (!dataMap[ts]) {
        dataMap[ts] = {
          timestamp: +ts,
        };
      }
      dataMap[ts][index] = value;
    });
    labels[index] = name;
  });

  const transformed = {
    data: sortBy(Object.values(dataMap), 'timestamp'),
    labels,
  };

  const table = [];
  const ts = transformed.data.map((value) => value.timestamp);
  Object.keys(transformed.labels).forEach((key) => {
    table.push(
      transformed.data.map((value) =>
        value[key] === undefined ? null : value[key],
      ),
    );
  });

  return {
    graphData: [ts, ...table],
    labels: Object.values(transformed.labels),
  };
};

const TimeSeriesPanel = ({
  data,
  timeRange,
  height,
  width,
  config: _config,
  tz = 'local',
  showAnnotations,
}) => {
  const formik = useFormikContext();
  const [config, setConfig] = useState(formik?.values || _config);
  const { timeseries, overrides, axis, tooltip } = config;
  const theme = useTheme();
  const { graphData, labels } = useMemo(
    () => transformFramesToTable(data),
    [data],
  );

  const { from, to } = useMemo(
    () =>
      typeof timeRange === 'string'
        ? parseValue(timeRange, _config.time_options?.time_shift)
        : {
            from: moment().subtract(timeRange.from, 'seconds').valueOf(),
            to: moment().subtract(timeRange.to, 'seconds').valueOf(),
          },
    [timeRange],
  );

  const annotations = useDefaultAnnotations({
    node_configs: config.node_configs,
    frameLabels: (data || []).map(({ labels }) => labels),
    from,
    to,
  });

  const timeValuesTpl = useMemo(() => {
    return formatTimeLabel({
      from,
      to,
      dateStyle: axis?.x?.date_style,
    });
  }, [from, to, axis?.x]);

  const tzDate = useTZDate(tz);

  const yTickFormatter = useCallback(
    (v) => {
      const result = format({
        ...axis?.y,
        value: v,
      });
      return formatResultToString(result);
    },
    [axis?.y],
  );

  const yRightTickFormatter = useCallback(
    (v) => {
      const result = format({
        ...axis?.y1,
        value: v,
      });
      return formatResultToString(result);
    },
    [axis?.y1],
  );

  const yAxisValues = useCallback(
    (_, values) => {
      return values.map(yTickFormatter);
    },
    [yTickFormatter],
  );

  const yRightAxisValues = useCallback(
    (_, values) => {
      return values.map(yRightTickFormatter);
    },
    [yRightTickFormatter],
  );

  const series = useMemo(() => {
    if (!labels || !labels.length || !data) {
      return undefined;
    }
    let series = [{}];

    labels.forEach((label, index) => {
      let color;

      if (defaultColors[index]) {
        color = defaultColors[index];
      } else {
        color = randomcolor({ luminosity: 'bright', seed: label + index });
      }

      series.push({
        points: { show: graphData[0]?.length === 1, size: 4 },
        stroke: color,
        name: label,
        scale: AXIS_PLACEMENT.left.value,
      });
    });

    for (const override of overrides || []) {
      const { properties } = override;

      const fieldsToOverride = getOverrideFields({ override, labels, data });

      if (!fieldsToOverride.length) {
        continue;
      }

      for (const property of properties || []) {
        const { value, type } = property;

        series = series.map((srs, index) => {
          switch (type) {
            case axisPlacementConfig.id: {
              // ignore x-axis (0 index)
              if (index === 0) {
                return srs;
              }
              if (value === undefined || value === AXIS_PLACEMENT.auto.value) {
                return srs;
              }
              const opp =
                value === AXIS_PLACEMENT.left.value
                  ? AXIS_PLACEMENT.right.value
                  : AXIS_PLACEMENT.left.value;

              return fieldsToOverride.includes(srs.name)
                ? {
                    ...srs,
                    scale: value,
                  }
                : {
                    ...srs,
                    scale: opp,
                  };
            }
            default:
              return srs;
          }
        });
      }
    }

    return series;
  }, [labels?.length, overrides, graphData[0]?.length]);

  useEffect(() => {
    formik && setConfig(formik.values);
  }, [formik?.values]);

  const [rangeMin, rangeMax] = useMemo(() => {
    if (!graphData[0]) {
      return [from, to];
    }
    const timeMin = graphData[0][0];
    const timeMax = graphData[0][graphData[0].length - 1];

    return [from > timeMin ? timeMin : from, timeMax < to ? to : timeMax];
  }, [from, to, graphData[0]]);

  return (
    <Box height={height} width={width}>
      {!data || !graphData.length || !graphData[0].length
        ? noDataMessage
        : data && (
            <Chart
              data={graphData}
              width={width}
              height={height}
              legendView={timeseries && timeseries.legend.view}
              chartSeries={series}
              legendAppendValues={
                timeseries && timeseries.legend.values.map(({ value }) => value)
              }
              tooltip={{
                multiSeriesTooltip: tooltip?.mode === TOOLTIP_MODES.all.value,
                showTooltip: tooltip?.mode !== TOOLTIP_MODES.hidden.value,
                highlightFocusedSeries: true,
              }}
              scales={{
                x: {
                  time: true,
                  range: [rangeMin, rangeMax],
                },
              }}
              cursor={{
                show: true,
                x: true,
                y: true,
                points: {
                  size: 4,
                },
                focus: {
                  prox: 30,
                },
              }}
              tzDate={tzDate}
              axes={[
                {
                  stroke: theme.palette.text.secondary,
                  grid: {
                    show: true,
                    stroke: theme.palette.text.secondary,
                    width: 0.3,
                    dash: [3],
                  },
                  border: {
                    show: true,
                    stroke: theme.palette.text.secondary,
                    width: 0.75,
                  },
                  values: timeValuesTpl,
                  space: () => 4.1 * timeValuesTpl.length + 34.1, // works fine for most cases. How it is calculated: space between x-axis ticks is directly proptional to the length of the ticks. Find 2 cases where spacing looks good. For eg. tickLength=5,space=55 and tickLength=11,space=80. Use these points to create a line equation y = mx + c.
                },
              ].concat(
                [AXIS_PLACEMENT.left.value, AXIS_PLACEMENT.right.value].map(
                  (yAxis) => {
                    return {
                      stroke: theme.palette.text.secondary,
                      grid: {
                        show: true,
                        stroke: theme.palette.text.secondary,
                        width: 0.3,
                        dash: [3],
                      },
                      border: {
                        show: true,
                        stroke: theme.palette.text.secondary,
                        width: 0.75,
                      },
                      values:
                        yAxis === AXIS_PLACEMENT.right.value
                          ? yRightAxisValues
                          : yAxisValues,
                      size: calcVertAxisWidth(),
                      side: yAxis === AXIS_PLACEMENT.right.value ? 1 : 3,
                      scale: yAxis,
                    };
                  },
                ),
              )}
              yValFormatter={yTickFormatter}
              yRightValFormatter={yRightTickFormatter}
              xTooltipFormatter={
                axis?.x?.date_style === 'auto' || !axis?.x?.date_style
                  ? (value) =>
                      moment(tzDate(value)).format('YYYY-MM-DD HH:mm:ss')
                  : uPlot.fmtDate(timeValuesTpl)
              }
              padding={[
                16,
                (_, sideNum, sidesWithAxes) =>
                  sidesWithAxes[sideNum] ? 0 : 32,
                0,
                (_, sideNum, sidesWithAxes) =>
                  sidesWithAxes[sideNum] ? 0 : 24,
              ]}
              threshold={timeseries?.threshold}
            >
              {showAnnotations
                ? annotations.map((annotation) => {
                    return (
                      <DefaultAnnotation annotation={annotation} tz={tz} />
                    );
                  })
                : null}
            </Chart>
          )}
    </Box>
  );
};

export default TimeSeriesPanel;
