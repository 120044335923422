import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from 'components/IconButton';
import Dropdown from 'components/Form/Dropdown';
import { FieldArray, useFormikContext } from 'formik';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import get from 'lodash/get';
import KeyField from './KeysField';

const SortBy = ({ name, from, to, variableOptions }) => {
  const { values, setFieldValue } = useFormikContext();

  const fieldName = `${name}.orderBy`;
  const orderBys = get(values, fieldName);
  const dataset = get(values, `${name}.dataset`);

  return (
    <Box mt={2}>
      <FieldArray name={fieldName}>
        {(helpers) => {
          return (
            <>
              <Stack mb={1} spacing={1} direction="row" alignItems="center">
                <Typography variant="body2">Add Order By</Typography>
                <IconButton
                  icon={<AddCircleIcon />}
                  size="small"
                  onClick={() =>
                    helpers.push({ field: '', type: '', order: 'desc' })
                  }
                />
              </Stack>
              <Stack spacing={0.25}>
                {orderBys.map((orderBy, idx) => {
                  return (
                    <Grid key={idx} container spacing={0.5}>
                      <Grid item xs={8}>
                        <KeyField
                          fullWidth
                          dataset={dataset}
                          name={`${fieldName}.${idx}`}
                          onChange={(name, value) => {
                            setFieldValue(name, {
                              ...orderBy,
                              field: value.field,
                              type: value.type,
                            });
                          }}
                          value={{ field: orderBy.field }}
                          from={from}
                          to={to}
                          variableOptions={variableOptions}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Dropdown
                          fullWidth
                          size="small"
                          placeholder="Order"
                          margin="none"
                          name={`${fieldName}.${idx}.order`}
                          onChange={(value) => {
                            setFieldValue(`${fieldName}.${idx}.order`, value);
                          }}
                          options={[
                            { label: 'ASC', value: 'asc' },
                            { label: 'DESC', value: 'desc' },
                          ]}
                          value={orderBy.order}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          color="error"
                          size="small"
                          icon={<RemoveCircleIcon />}
                          onClick={() => {
                            helpers.remove(idx);
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Stack>
            </>
          );
        }}
      </FieldArray>
    </Box>
  );
};

export default SortBy;
