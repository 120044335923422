import React, { useMemo } from 'react';
import moment from 'moment';
import { useFormikContext } from 'formik';
import Grid from '@mui/material/Grid';
import Aggregation from './Aggregation';
import Filters from './Filters';
import GroupBy from './GroupBy';
import AdditionalOptions from '../AdditionalOptions';
import { parseValue } from 'components/DateRangePickerNew';
import Range from './Range';

const AggQueryBuilder = ({
  name,
  values,
  variableOptions,
  from,
  to,
  onChangeUnit,
  resolvedVariablesValues,
}) => {
  const { setFieldValue } = useFormikContext();

  return (
    <Grid container columnSpacing={2} sx={{ mb: 1 }}>
      <Grid item xs={6} md={4}>
        <Aggregation
          queryOptions={values}
          updateQueryOptions={setFieldValue}
          variableOptions={variableOptions}
          name={name}
          from={from}
          to={to}
          onChangeUnit={onChangeUnit}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <GroupBy
          queryOptions={values}
          name={name}
          updateQueryOptions={setFieldValue}
          variableOptions={variableOptions}
          from={from}
          to={to}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Range name={name} />
      </Grid>
      <Grid item xs={12}>
        <Filters
          queryOptions={values}
          onChange={setFieldValue}
          variableOptions={variableOptions}
          resolvedVariablesValues={resolvedVariablesValues}
          name={name}
          from={from}
          to={to}
          showHint
        />
      </Grid>
      <Grid item xs={12}>
        <AdditionalOptions name={name} />
      </Grid>
    </Grid>
  );
};

export default AggQueryBuilder;
