import { QUERY_TYPE } from 'modules/alarms/utils';
import ListPanel from '../../ListPanel';
import { CONFIG as panelConfig } from '../../PanelSettings/Panel';
import { CONFIG as timeConfig } from '../../PanelSettings/TimeRangeOptions';
import RecentIssuesView from './RecentIssuesView';
import { DATA_SET } from 'constants/data-sources';

export const CONFIG = {
  id: 'issues',
  settings: {
    general: [panelConfig, timeConfig],
    overrides: null,
  },
  name: 'Issues List',
  description: 'Issues list',
  multiNodes: false,
  defaultNodeConfig: {
    query_type: QUERY_TYPE.NON_AGG,
    dataset: DATA_SET.ISSUES,
    limit: 10,
    page: 1,
  },
  showTableView: false,
  configurableSource: false,
};

const IssuesListPanel = ({ issues, error, ...listProps }) => (
  <ListPanel {...listProps}>
    <RecentIssuesView issues={issues} error={error} />
  </ListPanel>
);

export default IssuesListPanel;
